<template>
  <div class="role-wrap">
    <div class="orders">
      <div class="top">
        <SearchForm
          ref="searchForm"
          :form-list-extend="taskFormList"
          :form-data="searchFormData"
          style="padding: 0"
          :default-form="defaultForm"
          @update:formData="data=>searchFormData=data"
          @search="reSearch"
        />

      </div>
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        search-title="搜索工序定位码/编号/名称"
        style="padding: 10px 0 10px 0;"
        class="func-header"
        :tags="functionHeader.tags"
        @search="$refs.mTable.setTableData(true)"
    >
            <div slot="all" style="margin-left:10px;display: flex;align-items: center;">
              <el-button :disabled="!selectData.length" v-if="permission('BatchExport')" size="small" type="primary"  @click="batchExport()" >批量导出</el-button>
              <div class="all-select">
                <div class="checkAll" >
                  <el-checkbox v-model="checkeds" :indeterminate="isIndeterminate" @change="selectCheck">全选</el-checkbox>
                </div>
                <div v-for="(item, index) in allStatus" :key="item.value" class="all-li" :class="item.checked ? 'active' : ''" @click="selectTab(item, index)">{{ item.label }}</div>
              </div>
            </div>
          </FunctionHeader>
      <MTable
        ref="mTable"
        class="table-left"
        :columns="productionTaskColumn"
        :height="height"
        :set-data-method="getTableData"
        @selection-change="data=>selectData = data"
      >
        <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
         <NewPageOpen slot="code" slot-scope="{ row }"
                     :path="{ name: 'ProductionTaskDetail', query: { id: row.id }}" :text="row.code" />
        <div slot="productionStatus" slot-scope="{row }">
          {{ ['待开始','进行中','已完成'][row.productionStatus] }}
        </div>
          <div slot="assignStatus" slot-scope="{row }">
            {{ ['未派工','已派工'][row.assignStatus] }}
         </div>
        <NewPageOpen
          slot="materialsCode"
          slot-scope="{ row }"
          :path="{ name: 'materialsDetail', query: {id: row.materialsId,type:'view'}}"
          :text="row.materialsCode"
        />
        <NewPageOpen
          slot="procedureCode"
          slot-scope="{ row }"
          :path="{ name: 'procedureDetail', query: {id: row.procedureId,type:'view'}}"
          :text="row.procedureCode"
        />
        <template slot="planOutputQuantity" slot-scope="{ row }">
          <div>{{ row.planOutputQuantity }}</div>
          <!-- <el-input-number
            v-else
            v-model="row.planOutputQuantity"
            :clearable="false"
            :max="row.netRequireQuantity"
            :controls="false"
            :style="{border:row.isWarningPlanCount?'1px solid #F56C6C':'',borderRadius:'4px',width:'110px'}"
            @blur="e=>onBlur(e,row,'planOutputQuantity')"
          /> -->
        </template>
        <template slot="planTime" slot-scope="{ row }">
          <div v-if="!row.isEdit">{{ row.planTime[0] || row.planTime[1]  ? row.planTime.join(' 至 ') : '-' }}</div>
          <el-date-picker
            v-else
            v-model="row.planTime"
            type="daterange"
            :clearable="false"
            range-separator="至"
            start-placeholder="计划开工时间"
            end-placeholder="完工时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
          />
        </template>
        <div slot="executorName" slot-scope="{row }">
            {{ row.userList.length ? row.userList.map(item => item.name).join(',') :'-' }}
         </div>

        <template slot="remark" slot-scope="{ row }">
          <div v-if="!row.isEdit">{{ row.remark }}</div>
          <el-input v-else v-model="row.remark" />
        </template>

        <template slot="action" slot-scope="{ row }">
          <div>
            <span v-show="!row.isEdit">
              <el-button
                v-if="([0,1].includes(row.productionStatus) || hasEditData) && permission('Edit')"
                type="text"
                @click="modify(row)"
              >编辑<i
                class="el-icon-edit"
              /></el-button>
            </span>
            <span v-show="row.isEdit">
              <el-button type="text" @click="cancelModify(row)">取消</el-button>
              <el-button type="text" @click="onSave(row)">保存</el-button>
            </span>&nbsp;
            <!--<el-button-->
              <!--v-if="row.productionStatus === 0 && permission('Del')"-->
              <!--type="text"-->
              <!--@click="onDel([row.id])"-->
            <!--&gt;删除</el-button>-->
            <!--<el-button-->
              <!--v-if="+row.producePlanStatus!==1&&row.productionStatus === 2&& permission('Restart') "-->
              <!--type="text"-->
              <!--@click="restart(row)"-->
            <!--&gt;重启</el-button>-->
            <!--<el-tooltip effect="dark" content="所属生产工单已结案，无法重启" placement="top">-->
              <!--<el-button-->
                <!--v-if="+row.producePlanStatus===1&&row.productionStatus === 2&& permission('Restart') "-->
                <!--class="is-disabled"-->
                <!--type="text"-->
              <!--&gt;重启</el-button>-->
            <!--</el-tooltip>-->
            <el-button
              v-if="permission('Assign') "
              :type="assignProductionTask(row.userGroupList.length || row.userList.length?'已派工':'派工任务')"
              :disabled="row.productionStatus === 2"
              @click="assignClick(row, row.userGroupList.length|| row.userList.length)"
            >{{ row.userGroupList.length|| row.userList.length?'已派工':'派工任务' }}</el-button>
            <el-button v-if="permission('DelayRecord')&&row.feedbackTime" type="text" @click="viewDelayRecord(row)">延期记录</el-button>

            <el-divider v-if="permission('DelayFeedback') || permission('SetFactorySequenceNumber') || permission('Restart') ||
                      permission('PrintCertificate') || permission('StartTask') || permission('CompleteWork')" direction="vertical" />
            <el-dropdown v-if="permission('DelayFeedback') || permission('SetFactorySequenceNumber') || permission('Restart') ||
                      permission('PrintCertificate') || permission('StartTask') || permission('CompleteWork')" @command="(command) => { more(row, command) }">
                    <span class="el-dropdown-link">
                      更多<i class="el-icon-arrow-down el-icon--right" />
                    </span>
              <el-dropdown-menu slot="dropdown" class="td-dropdown-menu">
                <el-dropdown-item v-if="row.productionStatus === 0 && permission('StartTask')" type="text"
                                  command="startTask">开始任务</el-dropdown-item>
                <el-dropdown-item v-if="row.productionStatus === 1 && permission('CompleteWork')" type="text"
                                  command="completeWork">完工</el-dropdown-item>
                <el-dropdown-item v-if="row.productionStatus === 2&& permission('Restart')" type="text"
                                  command="restart">重启</el-dropdown-item>
                <el-dropdown-item v-if="permission('DelayFeedback')&&[0,1].includes(row.productionStatus)" type="text"
                                  command="onDelayFeedback">延期反馈</el-dropdown-item>
                <el-dropdown-item v-if="permission('SetFactorySequenceNumber') && row.factoryPrinting && !row.factorySequenceNumberRule" type="text"
                                  command="setFactorySequenceNumber">设置出厂序列号</el-dropdown-item>
                <el-dropdown-item v-if="permission('PrintCertificate') && row.factoryPrinting && row.factorySequenceNumber" type="text"
                                  command="print">打印</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </MTable>
    </div>

    <!-- 指派任务-->
    <MDialog v-model="assignVisibleNo" title="派工任务" @onOk="submitUser">
      <div style="text-align:right;margin-bottom:10px">
        <el-button
          v-if="showAssign"
          size="small"
          type="primary"
          @click="cancelAssign(1)"
        >取消派工</el-button>
      </div>
      <SelectUserAndUserGroup
        ref="userAndUserGroup"
        v-model="transferSelectIds"
        :is-assign="false"
        :user-options="userOptions"
        :user-group-options="userGroupOptions"
      />
    </MDialog>
        <!-- 延期记录-->
        <MDialog v-model="delayVisible" :show-footer="false" title="延期记录" @fullScreen="fullScreenChange">
      <MTable
        ref="dTable"
        :columns="delayItemColumns"
        :height="dialogHeight"
        :set-data-method="getDelayItemLog"
      >
        <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
         <div slot="attachments" slot-scope="{ row }">
            <div v-if="row.attachments">
              <span style="color:#6B8AFF;cursor: pointer;" @click="viewPicture(row)">查看</span>
              <el-image v-for="item in formatArr(imgList)" :key="item" style="width:0px; height: 0px" :src="item" :preview-src-list="formatArr(imgList)" fit="fill" ref="imgViewRef"/>
            </div>
            <div v-else>-</div>
         </div>
      </MTable>
    </MDialog>
    <MDialog v-model="stationVisible" title="设置出厂序列号" @onOk="submitStationForm">
          <el-input
          v-model="factorySequenceNumber"
          placeholder="出厂序列号"
          style="width: 100%"
        />
    </MDialog>
    <MDialog v-model="delayFeedbackVisible" title="延期反馈" @onOk="submitDelayFeedbackForm">
      <MFormBuilder ref="formBuild" :form-data="delayFeedbackFormData" :form-list="delayFeedbackFormList" >
        <el-upload slot="drawing" :file-list="delayAttachments" v-bind="uploadProps">
          <el-button type="primary" size="small">上传图片</el-button>
        </el-upload>
      </MFormBuilder>
    </MDialog>
  </div>
</template>
<script>
import api from '@/api/production/project'
import taskApi from '@/api/production/operation-task'
import FunctionHeader from '@/components/FunctionHeader'
import SearchForm from '@/components/SearchForm/SearchForm'
import { productionTaskColumn, taskFormList } from './data'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import SelectUserAndUserGroup from '@/components/SelectUserAndUserGroup/SelectUserAndUserGroup'
import dayjs from 'dayjs'
import projectApi from '@/api/projectManagement/project'
import uapi from '@/api/sets/user/user'
import {delayItemColumns } from '@/views/plans/prod-plan-lists/data'
import papi from '@/api/dataSetInfo/printingModule'
import { delayFeedbackFormList } from '@/views/plans/prod-plan-lists/form-list'
import { getToken } from '@/utils/auth'

export default {
  name: 'ProductionTaskList',
  components: { NewPageOpen, FunctionHeader, SearchForm, SelectUserAndUserGroup },
  data() {
    return {
      api,
      taskFormList,
      searchFormData: {},
      delayItemColumns,
      productionTaskId:'',
      stationVisible:false,
      factorySequenceNumber:'',
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('BatchAssign'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small', disabled: true },
            innerText: '批量派工',
            on: {
              click: () => this.batchAssignProductionTask('batch')
            }
          },
          {
            hidden: !this.permission('AllAssign'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            innerText: '全部派工',
            on: {
              click: () => this.batchAssignProductionTask('all')
            }
          }
        ]
      },
      productionTaskColumn,
      selectData: [],
      userOptions: [],
      userGroupOptions: [],
      transferSelectIds: [],
      assignVisibleNo: false,
      delayVisible: false,
      showAssign: false,
      hasEditData:false,
      // assignStatus: [0]
      defaultForm: {},
      assignProductionTask:function(val) {
        if (val == '已派工') {
            return 'success'
        } else {
            return 'danger'
        }
      },
      printLoading: false,
      isIndeterminate: false,
      checkeds: true,
      currentIdxs: [0, 1, 2],
      allStatus: [
        {
          value: 0,
          label: '待开始',
          checked: true
        },
        {
          value: 1,
          label: '进行中',
          checked: true
        },
        {
          value: 2,
          label: '已完成',
          checked: true
        }
      ],
      fullScreen:false,
      imgList:[],
      isPageLoaded:false,
      uploadProps: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        accept: 'image/*',
        beforeUpload: this.beforeUpload,
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange,
        onRemove: this.removeFile
      },
      delayFeedbackVisible: false,
      delayFeedbackFormList,
      delayFeedbackFormData: {},
      delayAttachments: []
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 65
    },
    memberCode(){
       return sessionStorage.getItem('memberCode')
    },
    dialogHeight() {
        return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 10 - 44: this.$store.state.app.contentHeight - 289 - 45 - 44
    }
  },
  watch: {
    selectData(val) {
      this.functionHeader.tags[0].props.disabled = val.length < 1
    },
    // checkeds(val) {
    //   if (val) {
    //     this.currentIdxs = []
    //     this.isIndeterminate = false
    //     this.allStatus = this.allStatus.map(item => {
    //       return {
    //         ...item,
    //         checked: false
    //       }
    //     })
    //   } else {
    //     this.isIndeterminate = true
    //   }
    // }
  },
  created(){
    this.getUserList()
    this.getBusinessUnitList()
    this.functionHeader.tags[1].hidden = this.memberCode === 'yyhl' ? true : false
    if(localStorage.getItem('productionTaskQuery')){
      this.defaultForm.assignStatus = localStorage.getItem('productionTaskQuery').split('^').map(item => {
        const num = Number(item)
        return isNaN(num) ? null : num
     })
    }
},
activated(){
    if(!this.isPageLoaded){
      this.$refs.mTable.setTableData()
    }
    this.isPageLoaded = false
    },
mounted(){
  this.isPageLoaded = true
},
  methods: {
    more(row,command) {
      const functions = {
        startTask: () => { this.startTask(row) },
        completeWork: () => { this.completeWork(row) },
        restart: () => { this.restart(row) },
        onDelayFeedback: () => { this.onDelayFeedback(row) },
        setFactorySequenceNumber: () => { this.setFactorySequenceNumber(row) },
        print: () => { this.print(row) },
      }
      functions[command]()
    },
    fullScreenChange(val) {
      this.fullScreen = val
    },
    viewPicture(row) {
      this.imgList = row.attachments
      setTimeout(() => {
        this.$refs.imgViewRef[0].clickHandler();
      }, 0);
    },
    formatArr(arr) {
      try {
        return JSON.parse(arr).map(item => {
          return item.url
        })
      } catch (err) {
        return []
      }
    },
    selectTab(val, index) {
      this.allStatus[index].checked = !this.allStatus[index].checked

      const isCheck = this.allStatus.filter(item => item.checked)
      const noCheck = this.allStatus.filter(item => !item.checked)

      if(isCheck.length > 0 && isCheck.length < this.allStatus.length){
        this.isIndeterminate = true
      }else {
        this.isIndeterminate = false
      }
      if(isCheck.length > 0){
        this.checkeds = true
        this.currentIdxs = isCheck.map(item => item.value)
      }else {
        this.checkeds = false
        this.currentIdxs = []
      }
      this.$refs.mTable.setTableData(true)
    },
    selectCheck(val) {
      this.currentIdxs = val ? [0,1,2] : [];
      this.isIndeterminate = false;
      if(val){
        this.allStatus = this.allStatus.map(item => {
          return {
            ...item,
            checked: true
          }
        })
      }else {
        this.allStatus = this.allStatus.map(item => {
          return {
            ...item,
            checked: false
          }
        })
      }
      this.$refs.mTable.setTableData(true)
    },
    setFactorySequenceNumber(row){
      this.factorySequenceNumber = row.factorySequenceNumber
      this.productionTaskId = row.id
      this.stationVisible = true
    },
    async submitStationForm(callback) {
          const res = await api.setFactorySequenceNumber(
           {
            productionTaskId:this.productionTaskId,
            factorySequenceNumber:this.factorySequenceNumber
          }
          )
          if (res) {
            this.$message.success('操作成功')
            this.stationVisible = false
            this.$refs.mTable.setTableData()
          }
          callback(true)
    },
    async print(row){
      this.printLoading = true
      const startTime = new Date().getTime()
      const res = await papi.commomDownload({
        wordTemplateId: row.wordTemplateId,
        type: "CERTIFICATE_OF_CONFORMITY",
        idList: row.factorySequenceNumberId,
        fileType: 1
      });

      const endTime = new Date().getTime()
      const elapsedTime = endTime - startTime
      if (res && res.data.byteLength) {
        this.printLoading = false
        const urlP = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/pdf'
          })
        );
        window.open(urlP)
      } else {
        if (elapsedTime >= 300000) {
          this.printLoading = false
          return this.$message.error('打印数据过多，请重新勾选')
        }
      }
    },
    // 执行人
    async getUserList() {
      const res = await uapi.getUserList()
      if (res) {
        this.taskFormList[6].children[0].options = res
      }
    },
    // 业务部门
    async getBusinessUnitList() {
      const res = await projectApi.getBusinessUnitList()
      if (res) {
        this.taskFormList[7].children[0].options = res
      }
    },
    // 延期反馈
    onDelayFeedback(row) {
      this.productionTaskId = row.id
      this.delayAttachments = []
      this.delayFeedbackVisible = true
    },

    createSetData(raw) {
      return {
        productionTaskId: this.productionTaskId,
        estimateEndTime: raw.estimateEndTime,
        delayReason: raw.delayReason,
        attachments: JSON.stringify(this.delayAttachments)
      }
    },

    async submitDelayFeedbackForm(callback) {
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          const res = await taskApi.addDelayFeedback(this.createSetData(form))
          if (res) {
            this.delayFeedbackVisible = false
            this.$refs.mTable.setTableData(true)
          }
          callback(true)
        } else {
          callback()
        }
      })
    },

    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20M!')
      }
      return isLt20M
    },
    fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        this.$message.success('上传成功')
        this.delayAttachments.push({
          id: file.uid,
          name: file.name,
          url: res.result
        })
      } else {
        this.$message.error(res.result)
      }
    },
    removeFile(file) {
      this.delayAttachments = this.delayAttachments.filter(item => item.uid !== file.uid)
    },

    async viewDelayRecord(row){
      this.delayVisible = true
      this.productionTaskId = row.id
      this.$nextTick(() => {
      this.$refs.dTable.setTableData()
      })
    },
    // 获取延期记录
    async getDelayItemLog(condition, callback) {
      const {page} = condition
      const res = await api.getDelayRecordList({
        page: page.currentPage,
        limit: page.pageSize,
        productionTaskId: this.productionTaskId
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
      async batchExport() {
          const params = { productionTaskIds: this.selectData.map(item => item.id) }
          const res = await api.exportProductionTask(params)
          if (res && res.data.byteLength) {
            const urlP = window.URL.createObjectURL(
              new Blob([res.data], {
                type: 'application/octet-stream;charset=ISO8859-1'
              })
            )
            const fileName = `${dayjs().format('YYYYMMDDHHmmss')}.xls`
            this.downloadFile(urlP, fileName)
          } else {
            this.$message.error('导出失败，内容为空')
          }
      },
      downloadFile(urlP, name) {
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = urlP
          link.setAttribute('download', name)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        },
    modify(row){
      this.$set(row,'isEdit',true)
      this.hasEditData = true
    },
    cancelModify(row){
      this.$set(row,'isEdit',false)
      this.hasEditData = false
    },
    async batchAssignProductionTask(assignType) {
      this.assignType = assignType
      this.produceWorkOrderTaskIds = this.selectData.map(item => item.id)
      this.transferSelectIds = []
      await this.getUser()
      this.assignVisibleNo = true
    },

    // 重启
    async restart(row) {
      this.$confirm('是否确认重启？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await taskApi.restartTask(row.id)
        if (res) {
          this.$message.success('操作成功')
          this.$refs.mTable.setTableData(true)
        }
      })
    },
    // 完工
    async completeWork(row) {
      this.$confirm('是否确认完工？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.completeWorkTask(row.id)
        if (res) {
          this.$message.success('操作成功')
          this.$refs.mTable.setTableData(true)
        }
      })
    },

    // 任务开始
    async startTask(row) {
      this.$confirm('是否确认开始任务？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.startTask(row.id)
        if (res) {
          this.$message.success('操作成功')
          this.$refs.mTable.setTableData(true)
        }
      })
    },

    // 操作指派任务
    async assignClick(item, isAssign) {
      this.assignType = 'single'
      this.showAssign = isAssign
      this.produceWorkOrderTaskIds = [item.id]
      await this.getUser(item.id)
      const userKeys = item.userList ? item.userList.map(item => item.key) : []
      const groupKeys = item.userGroupList ? item.userGroupList.map(item => item.key) : []
      this.transferSelectIds = [...userKeys, ...groupKeys]
      this.assignVisibleNo = true
    },
    // 用户组
    async getUser(id) {
      const res = await taskApi.getUsers()
      if (res) {
        this.userOptions = res.userList
        this.userGroupOptions = res.userGroupList
      }
    },
    // 取消指派
    async cancelAssign(type) {
      const res = await taskApi.cancelAssign(this.produceWorkOrderTaskIds[0])
      if (res) {
        this.assignVisibleNo = false
        this.$refs.mTable.setTableData()
      }
    },
    // 指派提交
    async submitUser(done) {
      done()
      const { user: userIdList, userGroup: userGroupIdList } = this.$refs.userAndUserGroup.getFormatData()
      if (userIdList.length === 0 && userGroupIdList.length === 0) {
        this.$message.error('指派人列表不能为空')
        return false
      }
      let params = {
        userIdList,
        userGroupIdList
      }
      let apiName
      if (this.assignType === 'single') {
        params.productionTaskId = this.produceWorkOrderTaskIds[0]
        apiName = 'getTaskUse'
      } else if (this.assignType === 'batch') {
        params.productionTaskIds = this.produceWorkOrderTaskIds
        apiName = 'batchAssignProductionTask'
      } else {
        params = { ...params, ...this.allAssignParams }
        apiName = 'allAssignProductionTask'
      }
      const res = await taskApi[apiName](params)
      if (res) {
        this.$message.success('指派成功')
        this.$refs.mTable.setTableData()
        this.assignVisibleNo = false
        this.$refs.mTable.tableRefs().clearSelection()
      }
    },
    async onDel(ids) {
      this.$confirm('是否确认删除？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delTask({ ids: ids.join(',') })
        if (res) this.$message.success('操作成功')
        this.$refs.mTable.setTableData(true)
      })
    },

    onSave(row) {
      this.$confirm('是否确认提交？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { id, planOutputQuantity, remark, planTime } = row
        const params = {
          id,
          planOutputQuantity,
          remark,
          planStartTime: planTime ? planTime[0] : null,
          planEndTime: planTime ? planTime[1] : null
        }
        const res = await api.updateTask(params)
        if (res) {
          this.$message.success('操作成功')
          this.$refs.mTable.setTableData(true)
          this.$set(row, 'isEdit', false)
          delete row.isEdit
          this.hasEditData = false
        }
      })
    },
    onBlur(e, row, key, num = 3) {
      const valStr = e.target.value + ''
      const decimalLength = valStr.split('.')[1]?.length
      if (decimalLength > num) {
        e.target.value = valStr.slice(0, valStr.length - (decimalLength - num)) * 1
        row[key] = e.target.value
      }
    },
    reSearch(data, val) {
      if (val === 1) {
        this.searchFormData = {}
      }
      if(data.length){
        localStorage.setItem("productionTaskQuery",data[0].fieldValue)
      }else{
        localStorage.setItem("productionTaskQuery",'')
      }
      setTimeout(() => {
        this.$refs.mTable.setTableData(true)
      }, this.searchFormData?.productionStatus?.length === 2 ? 300 : 0)
    },

    // 列表
    async getTableData(condition, callback) {
      const { page } = condition
      const { code, producePlanNumber, materials, planStartTime, planEndTime, assignStatus,businessUnit,userIds, sequenceNumber} = this.searchFormData
      const params = {
        producePlanNumber,
        materials,
        code,
        productionStatus: this.currentIdxs?.join(),
        assignStatus: assignStatus?.join(),
        procedure: this.functionHeader.searchVal,
        planStartStartTime: planStartTime ? planStartTime[0] : null,
        planStartEndTime: planStartTime ? planStartTime[1] : null,
        planEndStartTime: planEndTime ? planEndTime[0] : null,
        planEndEndTime: planEndTime ? planEndTime[1] : null,
        businessUnit,
        userIds,
        sequenceNumber
      }
      this.allAssignParams = params
      const res = await api.getTaskList({
        page: page.currentPage,
        size: page.pageSize,
        ...params
      })
      // this.notFirstSearch = true
      if (res) {
        res.records.forEach(item => {
          item.planTime = [item.planStartTime ? item.planStartTime : '', item.planEndTime ? item.planEndTime : '']
        })
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    }
  }
}
</script>

<style scoped lang="scss">
.orders {
  padding: 10px;
  .all-select {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 4px;
      .checkAll {
        margin-right: 20px;
      }
      .all-li {
        width: 80px;
        height: 26px;
        background: #FFFFFF;
        border-radius: 16px;
        border: 1px solid #DDE3EB;
        margin-right: 10px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A4B3C6;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .active {
        color: #607FFF;
        border-color: #607FFF;
        background: #EFF2FF;
      }
    }
}
::v-deep .custom-sel {
  div {
    min-width: 100%;
  }
}
::v-deep .el-table {
  .cell {
    display: flex;
    align-items: center;
  }
}
</style>
