<template>
  <div class="account-detail-wrapper">
    <div class="account-detail-header flex-sbc">
      <div class="left" @click="back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px"/>
        <span style="padding-left: 10px; cursor: pointer">项目详情</span>
      </div>
    </div>

    <div class="account-detail-body">
      <div class="detail-card" style="margin-bottom:20px">
        <div class="card-tit">项目基本信息</div>
        <div class="card-bot" style="padding: 15px">
          <div>
            <el-row type="flex" justify="start" class="view-list" style="padding: 10px 15px;flex-flow:row wrap"
                    :gutter="24">
              <el-col
                v-for="(item, index) in columns"
                :key="item.prop"
                :span="6"
                :style="{ marginBottom: index !== 4 ? '20px' : 0 }"
              >
                <span class="bot-tis">{{ item.label }}：</span>
                <span class="bot-tis">{{ getValue(item) }}</span>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div style="background:#FFFFFF">
        <el-tabs v-model="params.radio" @tab-click="tabClick" class="detail-tabs" type="border-card">
          <!--<el-tab-pane label="物料" name="ProjectMaterials" />-->
          <!--<el-tab-pane label="技术文件" name="Materials" />-->
          <el-tab-pane label="节点" name="PlanNode"/>
          <!--<el-tab-pane label="交付安排" name="InspectionRadio" />-->
          <el-tab-pane label="工单" name="ProjectWork"/>
          <el-tab-pane label="变更" name="ProjectECN"/>
          <el-tab-pane label="异常" name="ProjectAbnormal"/>
          <el-tab-pane label="用料记录" name="MaterialRecords"/>
          <el-tab-pane label="齐套分析" name="CompleteAnalysis"/>
          <el-tab-pane label="生产工时" name="WorkHourStatistics"/>
          <el-tab-pane label="汇报记录" name="ProjectReportRecords"/>
        </el-tabs>
        <template>
          <div class="bottom" style="padding:15px;">
            <FunctionHeader
              :tags="[]"
              v-model="functionHeader.searchVal"
              :search-title="searchTitle[params.radio]"
              @search="$refs.mTable.setTableData(true)"
              :isDialog="true"
            >
            </FunctionHeader>
            <MTable
              ref="mTable"
              :columns-key="columnsKey"
              :columns="tableColumns[params.radio]"
              :columns-setting="true"
              :set-data-method="getTableData"
              :default-expand-all="false"

              :expand-row-keys="forAutoExp"
              :is-tree-data="false"
              :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
              v-if="params.radio === 'PlanNode'"
            >
              <div slot="index" slot-scope="{row }">{{ !row.parentId ? row.sort + 1 : '' }}</div>
              <div slot="productionProgress" slot-scope="{ row }">
                <el-progress
                  :percentage="row.processPercent"
                  :color="row.type === 0 ? '#409eff' : row.type === 1 ? '#ffcd24' : '#2738c2'"
                />
              </div>
              <div slot="nodeAttribute" slot-scope="{ row }">
                <div v-if="row.nodeAttribute != null">{{ planNodeAttribute[row.nodeAttribute].name }}</div>
              </div>
              <div slot="principalName" slot-scope="{row }">
                <el-tooltip effect="dark" :content="row.principalName" placement="top-end">
                      <span class="mr-10">
                        {{ row.principalName }}
                    </span>
                </el-tooltip>
              </div>
              <div slot="projectPlanNodeAction" slot-scope="{ row, $index }">
                <el-button type="text" v-show="row.type === 0 && row.status != 0"
                           @click="viewPlanNodeDetail(row, $index)">任务详情
                </el-button>
              </div>
            </MTable>

            <MTable
              ref="mTable"
              :columns-key="columnsKey"
              :columns="tableColumns[params.radio]"
              :columns-setting="true"
              :set-data-method="getTableData"
              v-else
            >
              <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
              <div slot="faultStatus" slot-scope="{ row }">
                <div
                  class="faultStatus"
                  :style="{
                        background: faultStatus[row.status - 1].background,
                        color: faultStatus[row.status - 1].color,
                      }"
                >
                  {{ faultStatus[row.status - 1].name }}
                </div>
              </div>
              <div slot="workOrderStatus" slot-scope="{ row }">
                {{ workStatus[row.workOrderStatus + 1] }}
              </div>
              <div slot="changeType" slot-scope="{ row }">
                {{ changeType[row.changeType] }}
              </div>
              <div slot="status" slot-scope="{ row }">
                {{ preProductionPlanStatus[row.status] }}
              </div>
              <div slot="needOperate" slot-scope="{ row }">
                {{ needOperate[row.needOperate] }}
              </div>
              <div slot="majorType" slot-scope="{ row }">
                {{ majorType[row.majorType] }}
              </div>
              <div slot="status" slot-scope="{ row }">
                {{ abnormalOrderStatus[row.status] }}
              </div>
              <div slot="emergencyDegree" slot-scope="{ row }">
                {{ emergencyDegree[row.emergencyDegree] }}
              </div>
              <div slot="judgeResult" slot-scope="{ row }">
                {{ judgeResult[row.judgeResult] }}
              </div>
              <div slot="finalResult" slot-scope="{ row }">
                {{ finalResult[row.finalResult] }}
              </div>
              <div slot="processPercent" slot-scope="{ row }">
                <el-progress :percentage="row.processPercent"/>
              </div>
              <div slot="relatedFiles" slot-scope="{row }">
                <el-tooltip effect="dark" :content="row.relatedFiles" placement="top-end">
                  <span class="mr-10">{{ row.relatedFiles }}</span>
                </el-tooltip>
              </div>
              <div slot="attachments" slot-scope="{row }">
                <el-link v-for="item in JSON.parse(row.attachments) || []" :key="item.id" :href="item.url"
                         target="_blank" style="padding-left:10px;">
                  {{ item.name }}
                </el-link>
              </div>
              <NewPageOpen slot="abnormalOrderCode" slot-scope="{ row }"
                           :path="{ path: '/call/exception-handle/detail', query: { id: row.id }}"
                           :text="row.abnormalOrderCode"/>
              <NewPageOpen slot="prePlanChangeNumber" slot-scope="{ row }"
                           :path="{ name: 'ChangeManagementDetail', query: { id: row.id }}"
                           :text="row.prePlanChangeNumber"/>
              <div slot="receiptType" slot-scope="{ row }">{{ row.receiptType | receiptType }}</div>
              <div slot="directionType" slot-scope="{ row }">
                {{ row.directionType === 0 ? '工单领料' : row.directionType === 1 ? '工单退料' : '工单挪料' }}
              </div>
              <div slot="receiptStatus" slot-scope="{ row }">{{ row.receiptStatus | receiptStatus }}</div>
              <div slot="missMaterialsRate" slot-scope="{ row }">{{row.missMaterialsRate }}%</div>
              <div slot="completeRate" slot-scope="{ row }">{{row.completeRate }}%</div>
              <div slot="sitMissMaterialsRate" slot-scope="{ row }">{{row.sitMissMaterialsRate }}%</div>
              <div slot="isKeyMaterials" slot-scope="{ row }">{{row.isKeyMaterials === 1 ? '是' : '否'}}</div>
              <div slot="type" slot-scope="{ row }">
                {{ workHoursTypeMaps[row.type] || '-' }}
              </div>
              <div slot="sourceType" slot-scope="{ row }">
                {{ sourceTypeMaps[row.sourceType] || '-' }}
              </div>
              <div slot="userType" slot-scope="{ row }">
                {{ ['正式', '外包'][row.userType] || '-' }}
              </div>
              <template slot="quantity" slot-scope="{ row }">
                <div v-if="!row.isEdit">{{ row.quantity }}</div>
                <el-input v-else v-model="row.quantity"/>
              </template>
              <div slot="errorCount" slot-scope="{ row }">
                <div style="color: #e02020">{{ getErrorCount(row) }}</div>
              </div>
              <div slot="unMaintainCount" slot-scope="{ row }">
                <div style="color: #e02020">{{ getUnMaintainCount(row) }}</div>
              </div>
              <div slot="principalName" slot-scope="{row }">
                <el-tooltip effect="dark" :content="row.principalName" placement="top-end">
                      <span class="mr-10">
                        {{ row.principalName }}
                    </span>
                </el-tooltip>
              </div>
              <div slot="adjustRecord" slot-scope="{ row }">
                <el-button v-if="row.nodeAttribute === 1 || row.nodeAttribute === 3" type="text"
                           @click="viewAdjustRecord(row)">查看
                </el-button>
              </div>
              <div slot="workOrderNumber" slot-scope="{ row }">
                <a style="color: #607fff; font-weight: 500;" @click="viewWorkOrderDetail(row)">{{ row.workOrderNumber }}</a>
              </div>

              <div slot="workAction" slot-scope="{ row, $index }">
                <el-button type="text" :disabled="row.workOrderStatus != -1 && row.workOrderStatus != 0"
                           @click="viewMaterialsDetail(row, $index)">物料详情
                </el-button>
              </div>
              <div slot="projectEcnAction" slot-scope="{ row, $index }">
                <el-button type="text" @click="viewEcnChangeDetail(row, $index)">变更详情</el-button>
              </div>
              <div slot="projectAbnormalAction" slot-scope="{ row, $index }">
                <el-button type="text" @click="viewAbnormalDetail(row, $index)">异常详情</el-button>
              </div>
            </MTable>
          </div>
        </template>
      </div>
    </div>
    <!-- 物料选择弹窗 -->
    <select-materials
      :visible.sync="showMaterials"
      :select-materials="selectedMaterialList"
      :single="false"
      @submitForm="submitMaterials"
    />
    <!-- 新增计划节点 -->
    <MDialog v-model="visible" :title="planNodetitles[currentType]" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="planNodeFormList">
        <div slot="processFlowClassName">
          <CURDSelect
            :default-value="formData.planTypeId"
            api-name="PlanType"
            @change="selectName"
          />
        </div>
        <div slot="startTime">
          <el-date-picker v-show="showStartTime"
                          v-model="planStartTime"
                          type="datetime"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          format="yyyy-MM-dd HH:mm:ss"
                          style="width:100%"
                          placeholder="开始时间"
                          :disabled="isChooseStartAttr"
          />
          <div style="display:flex" v-show="!showStartTime">
            <el-select v-model="startPlanId" style="width:100%" placeholder="请选择" @change="changeStartTimePlan"
                       clearable filterable>
              <el-option
                v-for="item in planNodeTimeList"
                :key="item.id"
                :label="item.fullName"
                :value="item.id"
              />
            </el-select>
            <el-input-number style="margin-left:10px;"
                             v-model="startTimeBasis"
                             controls-position="right"
                             class="min-input"
            />
            天
          </div>
        </div>
        <div slot="finishTime">
          <el-date-picker v-show="showFinishTime"
                          v-model="planFinishTime"
                          type="datetime"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          format="yyyy-MM-dd HH:mm:ss"
                          style="width:100%"
                          defaultTime='23:59:59'
                          placeholder="完成时间"
                          :disabled="isChooseFinishAttr"
          />
          <div style="display:flex" v-show="!showFinishTime">
            <el-select v-model="finishPlanId" style="width:100%" placeholder="请选择" @change="changeFinishTimePlan"
                       clearable filterable>
              <el-option
                v-for="item in planNodeTimeList"
                :key="item.id"
                :label="item.fullName"
                :value="item.id"
              />
            </el-select>
            <el-input-number style="margin-left:10px;"
                             v-model="finishTimeBasis"
                             controls-position="right"
                             class="min-input"
            />
            天
          </div>
        </div>

      </MFormBuilder>
    </MDialog>
    <!-- 调整计划节点 -->
    <MDialog v-model="adjustVisible" title="调整计划节点" @onOk="submitAdjustForm">
      <MFormBuilder ref="adjustForm" :form-data="formData" :form-list="adjustFormList">

      </MFormBuilder>
    </MDialog>
    <MDialog v-model="viewAdjustRecordVisible" title="调整记录" :show-footer="false">
      <MTable
        :columns="adjustRecordColumns"
        :height="500"
        :data="viewAdjustRecordList"
        :show-page="false"
        :columns-setting="false"
      >
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
      </MTable>
    </MDialog>
  </div>
</template>

<script>
import api from '@/api/projectManagement/project'
import {
  columns,
  faultColumns,
  adjustRecordColumns,
  ProjectEcnColumns,
  repairColumns,
  workColumns,
  projectAbnormalColumns,
  materialRecordsColumns,
  completeAnalysisColumns,
  workHourStatisticsColumns,
  projectReportRecordsColumns,
  getFunctionList
} from './columns'
import FunctionHeader from '@/components/FunctionHeader/index'
import conventionMixin from '@/mixin/conventional-page'
import {
  faultSearchFormList,
  inspectionFormList,
  maintenanceFormList,
  planNodeFormList,
  adjustFormList
} from './form-list'
import {
  faultStatus,
  planNodeAttribute,
  startFinishTimeAttribute,
  docType,
  materialsRequisitionStatus
} from '@/config/options.config'
import { spareColumns } from '@/views/equipment/maintenance-bills/columns'
import CURDSelect from '@/components/CURDSelect/CURDSelect'
import SelectMaterials from '@/components/SelectMaterials/SelectMaterials.vue'
import processApi from '@/api/information/production/process'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import { workHoursTypeMaps, sourceTypeMaps } from '@/views/plans/prod-plan-lists/data'

export default {
  name: 'equipmentAccountDetails',
  components: { SelectMaterials, FunctionHeader, CURDSelect, NewPageOpen },
  mixins: [conventionMixin],
  filters: {
    receiptType(val) {
      if (val || val === 0) return docType.find(item => item.id === val).name
    },
    receiptStatus(val) {
      if (val || val === 0) return materialsRequisitionStatus.find(item => item.id === val).name
    }
  },
  data() {
    return {
      forAutoExp:[],
      faultColumns,
      adjustRecordColumns,
      faultStatus,
      spareColumns,
      planNodeFormList,
      adjustFormList,
      planNodeAttribute,
      startFinishTimeAttribute,
      viewAdjustRecordVisible: false,
      api,
      params: {
        radio: 'PlanNode'
      },
      workStatus: ['未开工', '进行中', '已结案', '装配完工', '完工', '入库', '冻结'],
      changeType: ['设计变更', '客户变更', '供应商变更'],
      preProductionPlanStatus: ['草稿', '审核中', '已审核', '执行中', '部分执行', '已执行'],
      needOperate: ['需要', '不需要'],
      majorType: ['全部', '电气', '机械'],
      emergencyDegree: ['紧急', '一般', '低'],
      abnormalOrderStatus: ['待开始', '待判定', '待处理', '处理中', '待验收', '验收中', '已关闭'],
      judgeResult: ['需要处理', '无需处理,直接关闭'],
      finalResult: ['已解决', '长期跟踪', '其他'],
      columns,
      tableColumns: {
        PlanNode: faultColumns,
        ProjectMaterials: repairColumns,
        ProjectWork: workColumns,
        ProjectECN: ProjectEcnColumns,
        ProjectAbnormal: projectAbnormalColumns,
        MaterialRecords: materialRecordsColumns,
        CompleteAnalysis: completeAnalysisColumns,
        WorkHourStatistics: workHourStatisticsColumns,
        ProjectReportRecords: projectReportRecordsColumns
      },
      projectDetail: {},
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: false,
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '增加物料',
            on: {
              click: this.add
            }
          }
        ]
      },
      functionProcessHeader: {
        searchVal: ''
      },
      addTitle: {
        PlanNode: '增加节点',
        ProjectMaterials: '增加物料',
        InspectionRadio: '请输入巡检单号'
      },
      searchTitle: {
        PlanNode: '请输入节点名称',
        ProjectMaterials: '请输入名称/编号/规格',
        ProjectWork: '请输入名称/编号/规格',
        InspectionRadio: '请输入巡检单号',
        ProjectECN: '请输入变更单号',
        ProjectAbnormal: '请输入异常单号/异常现象',
        MaterialRecords: '请输入领退料单名称/编号',
        CompleteAnalysis: '请输入物料名称/编号/规格',
        WorkHourStatistics: '请输入生产工单号/设备名称',
        ProjectReportRecords: '请输入相关文件/备注'
      },
      searchFormList: {
        PlanNode: faultSearchFormList,
        ProjectMaterials: faultSearchFormList,
        InspectionRadio: inspectionFormList,
        ProjectECN: maintenanceFormList
      },
      searchFormatData: [],
      checkItemList: [],
      tableDialogTitle: {
        InspectionRadio: '查看巡检项'
      },
      resultVisible: false,
      currentData: {},
      currentRow: {},
      spareList: [],
      searchFieldName: '',
      searchKeyword: true,
      allStatus: [],
      checkeds: true,
      currentIdxs: [],
      viewAdjustRecordList: [],
      currentIdx: false,
      isIndeterminate: false,
      visible: false,
      formData: {
        planTypeId: ''
      },
      currentType: 'add',
      planNodetitles: {
        add: '新增计划节点',
        modify: '修改计划节点'
      },
      dataProcess: [],
      page: {
        default: 20,
        pageSize: 20,
        pageSizes: [10, 20, 30, 50, 100, 150, 200],
        total: 0,
        currentPage: 1
      },
      showMaterials: false,
      selectedMaterialList: [],
      materialParams: { status: 0, page: 1, size: 20, search: '', materialsId: '' },
      adjustVisible: false,
      showFinishTime: true,
      showStartTime: true,
      planStartTime: '',
      planFinishTime: '',
      planNodeTimeList: [],
      startTimeRelationPlanId: '',
      finishTimeRelationPlanId: '',
      startTimeBasis: 0,
      finishTimeBasis: 0,
      startPlanId: '',
      finishPlanId: '',
      startTimeRelationTimeType: '',
      finishTimeRelationTimeType: '',
      planStartDateTime: '',
      planFinishDateTime: '',
      isChooseFinishAttr: true,
      isChooseStartAttr: true,
      planNodeId: '',
      workHoursTypeMaps,
      sourceTypeMaps
    }
  },
  computed: {
    tableDialogHeight() {
      return this.$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 70
    },
    height() {
      return this.$store.state.app.contentHeight - 256 - 40 - 22 - 100
    },
    tableHeight() {
      let { height } = this.$attrs
      const maxHeight = this.$attrs['max-height'] || this.$attrs.maxHeight
      if (maxHeight) return null
      if (!height) height = this.$store.state.app.contentHeight - 56 - 44 - 12 - 37 - 40 - 25
      return this.showPage ? height - 44 : height - 5
    },
    columnsKey() {
      return this.$route.name + this.params.radio
    }
  },
  watch: {},
  created() {
    this.getPlanObjectList()
  },
  beforeMount() {
    getFunctionList({
      startTimeAttributeChange: this.startTimeAttributeChange,
      finishTimeAttributeChange: this.finishTimeAttributeChange
    })
  },
  async mounted() {
    await this.getProjectDetail()
    await this.getPlanNodeTimeList()
    await this.getUserList()
  },
  methods: {
    selectName(item) {
      this.$refs.formBuild.setForm({
        planTypeId: item.id,
        planTypeName: item.name
      })
    },
    changeStartTimePlan(val) {
      const currentRow = this.planNodeTimeList.find(item => item.id === val)
      if (currentRow) {
        this.startTimeRelationPlanId = currentRow.planId
        this.startTimeRelationTimeType = currentRow.timeType
        this.planStartDateTime = currentRow.dateTime
      } else {
        this.startTimeRelationPlanId = ''
        this.startTimeRelationTimeType = ''
        this.planStartDateTime = ''
      }
    },
    changeFinishTimePlan(val) {
      const currentRow = this.planNodeTimeList.find(item => item.id === val)
      if (currentRow) {
        this.finishTimeRelationPlanId = currentRow.planId
        this.finishTimeRelationTimeType = currentRow.timeType
        this.planFinishDateTime = currentRow.dateTime
      } else {
        this.finishTimeRelationPlanId = ''
        this.finishTimeRelationTimeType = ''
        this.planFinishDateTime = ''
      }
    },
    startTimeAttributeChange(val) {
      if (val || val === 0) {
        this.isChooseStartAttr = false
        this.showStartTime = val === 2 ? false : true
      } else {
        this.isChooseStartAttr = true
        this.showStartTime = true
      }
    },
    finishTimeAttributeChange(val) {
      if (val || val === 0) {
        this.isChooseFinishAttr = false
        this.showFinishTime = val === 2 ? false : true
      } else {
        this.isChooseFinishAttr = true
        this.showFinishTime = true
      }

    },
    viewWorkOrderDetail(row) {
      this.$router.push({
        name: 'prodPlanCreate',
        query: { id: row.workOrderId, type: 'view' }
      })
    },
    viewMaterialsDetail(row) {
      this.$router.push({
        name: 'materialsRequireDetail',
        query: { id: row.workOrderId, type: 0 }
      })
    },
    viewPlanNodeDetail(row) {
      this.$router.push({
        name: 'ProjectTaskDetail',
        query: { id: row.id, type: 0 }
      })
    },
    viewEcnChangeDetail(row) {
      this.$router.push({
        name: 'ChangeManagementDetail',
        query: { id: row.id, type: 0 }
      })
    },
    viewAbnormalDetail(row) {
      this.$router.push({
        path: '/call/exception-handle/detail',
        query: { id: row.id, type: 0 }
      })
    },
    viewAdjustRecord(row) {
      this.viewAdjustRecordList = row.adjustRecordList ? row.adjustRecordList : []
      this.viewAdjustRecordVisible = true
    },
    add() {
      if (this.params.radio === 'PlanNode') {
        this.formData = {}
        this.planStartTime = ''
        this.planFinishTime = ''
        this.showStartTime = true
        this.showFinishTime = true
        this.startTimeBasis = 0
        this.finishTimeBasis = 0
        this.startPlanId = ''
        this.finishPlanId = ''
        this.isChooseStartAttr = true
        this.isChooseFinishAttr = true
        this.planNodeId = ''
        this.currentType = 'add'
        this.getPlanObjectList()
        this.getPlanNodeTimeList()
        this.visible = true
      } else if (this.params.radio === 'ProjectMaterials') {
        this.showMaterials = true
      }
    },
    modify(row) {
      this.formData = this._.cloneDeep(row)
      this.formData.principalId = this.formData.principalId ? this.formData.principalId.split(',').map(item => +item) : this.formData.principalId
      this.planNodeId = row.id
      this.planStartTime = row.startTime
      this.planFinishTime = row.finishTime
      this.startTimeBasis = row.startTimeBasis
      this.finishTimeBasis = row.finishTimeBasis
      this.startPlanId = row.startTimeRelationPlanId ? row.startTimeRelationPlanId + '-' + row.startTimeRelationTimeType : ''
      this.finishPlanId = row.finishTimeRelationPlanId ? row.finishTimeRelationPlanId + '-' + row.finishTimeRelationTimeType : ''
      this.planStartDateTime = this.planNodeTimeList.find(item => item.id === this.startPlanId) ? this.planNodeTimeList.find(item => item.id === this.startPlanId).dateTime : ''
      this.startTimeRelationPlanId = row.startTimeRelationPlanId
      this.startTimeRelationTimeType = row.startTimeRelationTimeType
      this.finishTimeRelationPlanId = row.finishTimeRelationPlanId
      this.finishTimeRelationTimeType = row.finishTimeRelationTimeType
      this.planFinishDateTime = this.planNodeTimeList.find(item => item.id === this.finishPlanId) ? this.planNodeTimeList.find(item => item.id === this.finishPlanId).dateTime : ''
      this.showStartTime = this.formData.startTimeAttribute === 2 ? false : true
      this.showFinishTime = this.formData.finishTimeAttribute === 2 ? false : true
      this.isChooseFinishAttr = this.formData.finishTimeAttribute || this.formData.finishTimeAttribute === 0 ? false : true
      this.isChooseStartAttr = this.formData.startTimeAttribute || this.formData.startTimeAttribute === 0 ? false : true
      this.getPlanNodeTimeList()
      this.currentType = 'modify'
      this.visible = true
    },
    adjust(row) {
      this.formData = this._.cloneDeep(row)
      if (row.startTimeAttribute != 1) {
        this.adjustFormList[2].props.disabled = true
      } else {
        this.adjustFormList[2].props.disabled = false
      }
      if (row.finishTimeAttribute != 1) {
        this.adjustFormList[3].props.disabled = true
      } else {
        this.adjustFormList[3].props.disabled = false
      }
      this.adjustVisible = true
    },
    // 用户下拉触底分页加载
    finishedMaterialLoadMore() {
      if (this.planNodeFormList[3].children[0].options.length === this.materialsTotal) return
      if (this.planNodeFormList[3].children[0].options.length > this.materialsTotal) return [...this.planNodeFormList[3].children[0].options = new Set(this.planNodeFormList[3].children[0].options)]
      this.materialParams.page++
      this.getFinishedMaterials(this.materialParams)
    },
    // 获取负责人
    async getFinishedMaterials(params) {
      const res = await processApi.getUserList(params)
      if (res) {
        this.materialsTotal = res.total
        this.planNodeFormList[3].children[0].options.push(...res.records || [])
      }
    },
    // 获取负责人
    async getUserList() {
      const res = await api.getResponsible()
      if (res) {
        this.planNodeFormList[3].children[0].options = res
      }
    },
    // 获取计划对象
    async getPlanObjectList() {
      const res = await api.getPlanObjectList({ projectId: this.$route.query.id })
      if (res) {
        this.planNodeFormList[0].children[0].options = res
      }
    },
    async onSave(row) {
      const res = await api.updateProjectMaterials({
        quantity: row.quantity,
        id: row.id
      })
      if (res) {
        this.$message.success('操作成功')
        this.$refs.mTable.setTableData(true)
        this.$set(row, 'isEdit', false)
        delete row.isEdit
      }
    },
    async del(row) {
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await this.api[`del${this.params.radio}`]({
          id: row.id
        })
        if (res) {
          this.$message.success('操作成功')
          this.$refs.mTable.setTableData(true)
        }
      })
    },
    handleSizeChange(val) {
      this.page.pageSize = val
      this.getProcedureMaterialsListPage()
    },
    // 当前页变化
    handleCurrentChange(val) {
      this.page.currentPage = val
      this.getProcedureMaterialsListPage()
    },
    async getProjectDetail() {
      const res = await api.getProjectDetail({ id: this.$route.query.id })
      if (res) {
        this.projectDetail = res
      }
    },
    async getPlanNodeTimeList() {
      const res = await api.getPlanNodeTimeList({ projectId: this.$route.query.id, planId: this.planNodeId })
      if (res) {
        this.planNodeTimeList = res
      }
    },
    async getTableData(condition, callback) {
      const { page, search, order } = condition

      const data = Object.assign({}, ...this.searchFormatData)
      const searchCondition = {
        search: this.functionHeader.searchVal,
        ...data
      }
      this.functionHeader.exportParams = searchCondition
      const res = await this.api[`get${this.params.radio}ListPage`]({
        page: page.currentPage,
        size: page.pageSize,
        projectId: this.$route.query.id,
        ...searchCondition
      })
      if (res) {
        let arr = this._.cloneDeep(res.records)
        if (this.params.radio === 'ProjectMaterials') {
          this.selectedMaterialList = arr.map((item) => {
            return {
              ...item,
              id: item.materialsId,
              selectable: true
            }
          })
        }

        this.forAutoExp = []
        if(this.params.radio === 'PlanNode'){
          let arrp = []
          res.records.forEach(m =>{
            if(!m.parentId){
              m.idConnect = m.id + ''
            }
            if(m.children && m.children.length > 0){
              this.handleInit(m)
            }
          })
          arrp = this.handleDG(res.records)
          this.$set(this, 'forAutoExp', arrp)
        }
        callback({
          total: res.total,
          content: res.records.map((item, index) => {
            return {
              ...item,
              sort: index
            }
          })
        })
      }
      callback()
    },
    handleInit(p){
      p.children.forEach(k => {
        k.idConnect = p.idConnect + '^' + k.id
        if(k.children && k.children.length > 0){
          this.handleInit(k)
        }
      })
    },
    handleDG(data){
      const arpi = new Set()
      _dg(data)
      return [...arpi]

      function _dg(data=[]){
        data.forEach(k => {
          if(k.planTypeName === '生产工单'){
            if(k.parentId){
              k.idConnect.split('^').forEach((m,i) =>{
                if(i < k.idConnect.split('^').length - 1){
                  arpi.add("" + m)
                }
              })
            }
            return
          }else {
            if(k.children && k.children.length > 0){
              _dg(k.children)
            }else {
              return
            }
          }
        })
      }
    },
    async submitAdjustForm(callback) {
      const { form } = this.$refs.adjustForm
      const res = await api.adjustPlan({
        planNodeId: form.id,
        startTime: form.startTime,
        finishTime: form.finishTime,
        adjustReason: form.adjustReason
      })
      if (res) {
        this.adjustVisible = false
        this.$message.success('调整成功')
        this.$refs.mTable.setTableData(true)
      }
      callback()
    },
    createSetData(raw) {
      return {
        projectId: this.$route.query.id,
        planName: raw.planName,
        planTypeId: raw.planTypeId,
        planObjectId: raw.planObjectId,
        startTimeAttribute: raw.startTimeAttribute,
        finishTimeAttribute: raw.finishTimeAttribute,
        startTime: raw.startTimeAttribute === 2 ? this.planStartDateTime : this.planStartTime,
        finishTime: raw.finishTimeAttribute === 2 ? this.planFinishDateTime : this.planFinishTime,
        principalId: raw.principalId ? raw.principalId.join(',') : '',
        remark: raw.remark,
        startTimeRelationPlanId: raw.startTimeAttribute === 2 ? this.startTimeRelationPlanId : '',
        startTimeRelationTimeType: raw.startTimeAttribute === 2 ? this.startTimeRelationTimeType : '',
        startTimeBasis: raw.startTimeAttribute === 2 ? this.startTimeBasis : '',
        finishTimeRelationPlanId: raw.finishTimeAttribute === 2 ? this.finishTimeRelationPlanId : '',
        finishTimeRelationTimeType: raw.finishTimeAttribute === 2 ? this.finishTimeRelationTimeType : '',
        finishTimeBasis: raw.finishTimeAttribute === 2 ? this.finishTimeBasis : ''
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    },
    async submitMaterials(val) {
      const res = await api.addProjectMaterials({
        projectId: this.$route.query.id,
        materialsIds: val.map((item) => item.id)
      })
      if (res) {
        this.showMaterials = false
        this.$message.success('新增成功')
        if (this.$refs.mTable) this.$refs.mTable.setTableData(true)
      }
    },
    getValue(row) {
      const result = this.projectDetail[row.prop]
      if (row.form && row.form.options) {
        const current = row.form.options.find((item) => item.id === result)
        return current && current.name
      }
      if (row.prop === 'deliveryDate') {
        return this.projectDetail.deliveryStartDate + '~' + this.projectDetail.deliveryEndDate
      }
      return result
    },
    back() {
      this.$router.back()
    },
    submitPlanNodeForm(callback) {
      callback(true)
    },
    searchTable(data) {
      this.searchFormatData = data
      if (this.$refs.mTable) this.$refs.mTable.setTableData(true)
    },
    tabClick() {
      this.checkeds = true
      this.currentIdxs = []
      this.currentIdx = false
      this.isIndeterminate = false
      if (this.params.radio === 'ProjectWork' || this.params.radio === 'ProjectECN' || this.params.radio === 'ProjectAbnormal' ||
        this.params.radio === 'PlanNode' || this.params.radio === 'ProjectMaterials') {
        this.functionHeader.tags[0].hidden = true
      } else {
        this.functionHeader.tags[0].hidden = false
        this.functionHeader.tags[0].innerText = this.addTitle[this.params.radio]
      }
      if (this.params.radio) if (this.$refs.searchForm) this.$refs.searchForm.form = {}
      this.searchKeyword = ['PlanNode', 'ProjectMaterials'].includes(this.params.radio)
      const keyObj = {
        InspectionRadio: 'cycleCheckTaskNumber',
        PointRadio: 'spotCheckNumber'
      }
      this.searchFieldName = keyObj[this.params.radio]
      this.searchFormatData = []
      setTimeout(() => {
        if (this.$refs.mTable) this.$refs.mTable.setTableData(true)
      }, 0)
    },
    async getProcedureMaterialsListPage() {
      const searchCondition = {
        projectId: this.$route.query.id,
        search: this.functionProcessHeader.searchVal
      }
      const res = await api.getProjectWork({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchCondition
      })
      if (res) {
        this.dataProcess = res.records
        this.page.total = res.total
      }
    },
    viewCheckDetail(row) {
      this.currentData = row
      this.resultVisible = true
    },
    getErrorCount(row) {
      if (row.status === 1 || row.status === 0 || row.status === 4 || !row.checkItemList) {
        return ''
      }
      return row.checkItemList.filter((item) => item.result === 0).length
    },
    getUnMaintainCount(row) {
      if (row.status === 1 || row.status === 0 || row.status === 4 || !row.maintenanceItemList) {
        return ''
      }
      return row.maintenanceItemList.filter((item) => item.result !== 1).length
    }
  }
}
</script>

<style scoped lang="scss">
.account-detail-wrapper {
  .account-detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .account-detail-body {
    padding-top: 10px;

    .card-bot {
      .info {
        padding: 20px;
        border-bottom: 1px solid #dde3eb;
      }
    }
  }
}

.faultStatus {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  border-radius: 4px;
  width: 50px;
  text-align: center;
}

.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}

.page-container {
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

.result-wrapper {
  > div {
    display: flex;
    font-size: 16px;
    padding: 10px 0;

    span {
      &:first-child {
        width: 80px;
        text-align-last: justify;
        text-align: justify;
      }
    }
  }
}

::v-deep {
  .table-row-img {
    > img {
      height: 50px;
    }
  }

  .el-tabs .el-tabs__content {
    padding: 0;
  }
}

::v-deep {
  .el-form-item__content {
    height: 32px;
  }
}

.all-select {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .checkAll {
    margin-right: 20px;
  }

  .all-li {
    width: 80px;
    height: 26px;
    background: #ffffff;
    border-radius: 16px;
    border: 1px solid #dde3eb;
    margin-right: 10px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a4b3c6;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .active {
    color: #607fff;
    border-color: #607fff;
    background: #eff2ff;
  }
}
</style>
