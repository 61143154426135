<template>
    <div class="account-detail-wrapper">
      <div class="account-detail-body">
        <div class="detail-card" style="margin-bottom:20px;">
          <div class="card-tit">基本信息</div>
          <div class="card-bot" style="padding: 15px">
            <div>
              <el-row
                type="flex"
                justify="start"
                style="padding: 10px 15px; flex-flow: row wrap"
                :gutter="24"
              >
                <el-col
                  v-for="item in getBaseColumns"
                  :key="item.prop"
                  :span="6"
                  :style="{ marginBottom: '20px'}"
                >
                  <span class="bot-tis">{{ item.label }}：</span>
                  <span class="bot-tis">{{ getValue(item) }}</span>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
        <div style="background:#FFFFFF">
              <el-tabs v-model="params.radio" @tab-click="tabClick" class="detail-tabs" type="border-card">
                <el-tab-pane label="报工记录" name="MaterialsChange" />
                <el-tab-pane label="计划用料" name="MaterialPlan" />
                <el-tab-pane label="投料记录" name="InputMaterialsRecords" />
                <el-tab-pane label="自检记录" name="InvolveWork"/>
                <el-tab-pane label="专检记录" name="SpecialInspectionRecords"/>
                <el-tab-pane label="图纸附件" name="PlanNode" />
                <el-tab-pane label="延期记录" name="DelayRecords" />
              </el-tabs>
              <template v-if="params.radio === 'MaterialsChange'">
                <div class="bottom" style="padding:15px;margin-bottom: 38px">

                  <MTable
                    ref="mTable"
                    :columns="tableColumns[params.radio]"
                    :data="reportWorkRecordList"
                    :show-page="false"
                    :height="400"
                  >
                    <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
                    <div slot="workHoursType" slot-scope="{ row }">{{ workHoursType[row.type] }}
                  </div>
                  <div slot="planTime" slot-scope="{row }">{{ row.planStartTime }} 至 {{ row.planEndTime }}
                  </div>
                  </MTable>
                </div>
              </template>
              <template v-if="params.radio === 'InputMaterialsRecords'">
                <div class="bottom" style="padding:15px;margin-bottom: 38px">

                  <MTable
                    ref="mTable"
                    :columns="tableColumns[params.radio]"
                    :data="equipmentDetail.inputMaterialsRecordList"
                    :show-page="false"
                    :height="400"
                  >
                    <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
                    <div slot="inputMaterialsRecordStatus" slot-scope="{row }">{{ row.status === 0 ? '已完成' : '已撤销' }}</div>
                    <div slot="mode" slot-scope="{row }">{{ row.mode === 0 ? '人工' : '倒冲' }}</div>
                    <div slot="isKeyMaterials" slot-scope="{row }">{{ row.isKeyMaterials === 0 ? '否' : '是' }}</div>
                    <div slot="settingVariable" slot-scope="{row }">{{ row.settingVariable === 0 ? '否' : '是' }}</div>
                  </MTable>
                </div>
              </template>
              <template v-if="params.radio === 'MaterialPlan'">
                <div class="bottom" style="padding:15px;margin-bottom: 38px">
                  <MTable
                    ref="mTable"
                    :columns="tableColumns[params.radio]"
                    :data="equipmentDetail.materialsRecordList"
                    :show-page="false"
                    :height="400"
                  >
                    <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
                  </MTable>
                </div>
              </template>
              <template v-if="params.radio === 'InvolveWork' || params.radio === 'SpecialInspectionRecords'" >
                <div class="bottom" style="padding:15px;margin-bottom: 38px">
                  <MTable
                    ref="sTable"
                    :columns="tableColumns[params.radio]"
                    :set-data-method="getTableData"
                    :show-page="true"
                    :height="400"
                  >
                    <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
                    <div slot="code" slot-scope="{ row }">
                      <NewPageOpen :path="{ name: 'QualityTaskDetail', query: {id: row.id, type: 'view'}}"
                                   :text="row.code"/>
                    </div>

                    <div slot="inspectionType" slot-scope="{ row }">{{ inspectionTypeNames[row.type] }}</div>
                    <div slot="inspectType" slot-scope="{ row }">{{ row.inspectType === 1 ? '专检' : '自检' }}</div>
                    <div slot="status" slot-scope="{ row }">{{ statusName[row.status] }}</div>
                    <div slot="result" slot-scope="{ row }">
                    <span :style="{color:row.result == 0 ? '#E02020':row.result === 1 ? '#00AB29':''}">
                      {{ row.result === 1 ? '合格' : row.result === 0 ? '不合格' : '-' }}
                    </span>
                    </div>
                  </MTable>
                </div>
              </template>
              <template v-if="params.radio === 'PlanNode'">
                <div class="bottom" style="padding:15px;margin-bottom: 38px">
                    <div class="detail-card">
          <div class="card-bot">
            <div class="b20 flex-sbc">
              <img src="@/assets/information/procedure/图纸@2x.png" style="width: 18px">
              <span class="l10" style="position: relative;top: 2px">图纸</span>
            </div>
            <br>
            <MTable ref="mTable" :show-page="false" :height="300" :columns="tableColumns[params.radio]" :data="drawingsList">
              <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
              <div slot="fileName" slot-scope="{ row }">
                <a :href="row.url" :download="row.name" target="_blank" style="color: #607FFF">{{ row.name }}</a>
                <img src="@/assets/information/procedure/附件@2x.png" style="width: 11px;margin-left: 5px">
              </div>
            </MTable>

            <div class="bt20 flex-sbc">
              <img src="@/assets/information/procedure/其他附件@2x.png" style="width: 18px">
              <span class="l10" style="position: relative;top: 2px">其他附件</span>
            </div>
            <br>
            <MTable
              ref="mTable"
              :show-page="false"
              :height="300"
              :columns="tableColumns[params.radio]"
              :data="filesList"
            >
                    <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
                    <div slot="fileName" slot-scope="{ row }">
                      <a
                        :href="row.url"
                        :download="row.name"
                        target="_blank"
                        style="color: #607FFF"
                      >{{ row.name }}</a>
                      <img
                        src="@/assets/information/procedure/附件@2x.png"
                        style="width: 11px;margin-left: 5px"
                      >
                    </div>

                  </MTable>
                </div>
              </div>
                </div>
              </template>
              <template v-if="params.radio === 'DelayRecords'">
                <div class="bottom" style="padding:15px;margin-bottom: 38px">
                  <MTable
                    ref="mTable"
                    :columns="tableColumns[params.radio]"
                    :data="delayRecordsList"
                    :show-page="false"
                    :height="400"
                  >
                  <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
                    <div slot="attachments" slot-scope="{ row }">
                        <div v-if="row.attachments">
                        <span style="color:#6B8AFF;cursor: pointer;" @click="viewPicture(row)">查看</span>
                        <el-image v-for="item in formatArr(imgList)" :key="item" style="width:0px; height: 0px" :src="item" :preview-src-list="formatArr(imgList)" fit="fill" ref="imgViewRef"/>
                        </div>
                        <div v-else>-</div>
                    </div>

                  </MTable>
                </div>
              </template>
            </div>
      </div>
      <div class="order-footer">
        <div />
        <div class="footer-ri">
          <el-button @click="back()">返 回</el-button>

        </div>
      </div>
    </div>
  </template>

    <script>
import api from '@/api/production/project'
  import {
    columns,
    materialColumns,
    workColumns,
    delayItemColumns,
    inputMaterialsRecordColumns,
    materialPlanColumns,
    fileAndDrawings
  } from "./columns";

  import FunctionHeader from "@/components/FunctionHeader/index";
  import conventionMixin from "@/mixin/conventional-page";
  import qapi from '@/api/qualityManagement/quality-task'
  import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
  export default {
    name: "ProductionTaskDetail",
    components: { FunctionHeader, NewPageOpen },
    mixins: [conventionMixin],
    data() {
      return {
        materialColumns,
        api,
        params: {
          radio: "MaterialsChange",
        },
        columns,
        tableColumns: {
          PlanNode: fileAndDrawings,
          MaterialsChange: materialColumns,
          InvolveWork: workColumns,
          SpecialInspectionRecords: workColumns,
          DelayRecords: delayItemColumns,
          InputMaterialsRecords:inputMaterialsRecordColumns,
          MaterialPlan:materialPlanColumns
        },
        equipmentDetail: {},
        searchFormatData: [],
        searchFieldName: "",
        searchKeyword: true,
        extraParams: [],
        checkeds: true,
        dataProcess: [],
        processList: [],
        materialsChangeList: [],
        newMaterialsChangeList: [],
        upperMaterialsId:[],
        fullScreen:false,
        materialsData:[],
        processBatchList:[],
        chooseMaterialsList:[],
        reportWorkRecordList:[],
        delayRecordsList:[],
        inputMaterialsRecordList:[],
        drawingsList:[],
        filesList:[],
        imgList:[],
        statusName: ['未开始','质检中','已完成'],
        workHoursType: {
        0: '生产工时',
        1: '浪费工时',
        2: '返工',
        3: '5S',
        4: '会议',
        5: '培训',
        6: '入职',
        7: '售后',
        11: '停工',
      },
      inspectionTypeNames: ['首检', '过程检', '来料检', '出货检']
      };
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 256 - 40 - 22 - 100 - 60;
      },
        memberCode() {
          return sessionStorage.getItem('memberCode')
        },
        getBaseColumns(){
          if(this.memberCode != 'yyhl'){
            return this.columns.filter(item => item.label !='实际进度')
          } else {
            return this.columns
          }
      }
    },
    async mounted() {
      await this.getWorkChangeDetail()
      await this.getSelfInspectRecord()
    },
    methods: {
     // 获取生产任务下延期记录
      async getDelayItemLog(condition, callback) {
      const {page} = condition
      const res = await api.getDelayRecordList({
        page: page.currentPage,
        limit: page.pageSize,
        productionTaskId: this.$route.query.id
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    viewPicture(row) {
      this.imgList = row.attachments
      setTimeout(() => {
        this.$refs.imgViewRef[0].clickHandler();
      }, 0);
    },

    formatArr(arr) {
      try {
        return JSON.parse(arr).map(item => {
          return item.url
        })
      } catch (err) {
        return []
      }
    },
      fullScreenChange(val) {
        this.fullScreen = val
      },
    selectProcess(val){
      this.materialsData.forEach(item => {
        item.procedureId = val
    })
    },
      async getWorkChangeDetail() {
        const res = await api.getTaskDetail({ productionTaskId: this.$route.query.id });
        if (res) {
          this.equipmentDetail = res;
          this.drawingsList =  res.procedureDrawing && JSON.parse(res.procedureDrawing)
          this.filesList = res.procedureAttachment && JSON.parse(res.procedureAttachment)
          this.reportWorkRecordList = res.reportWorkRecordList;
          this.delayRecordsList = res.delayRecords
        }
      },
    // 列表
    async getTableData(condition, callback) {
      const {page, order} = condition
      const res = await qapi.getQualityTaskListPage({
        page: page.currentPage,
        size: page.pageSize,
        sort: order,
        productionTaskId: this.$route.query.id,
        inspectTypes: this.params.radio === "InvolveWork" ? [0] : [1]
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      callback()
    },
      getValue(row) {
        const result = this.equipmentDetail[row.prop];
        if (row.prop === "productionStatus") {
          return ['待开始','进行中','已完成'][result]
        }
        if (row.prop === "taskProcessPercent" || row.prop === 'actualProcessPercent') {
          return result + "%";
        }
        if (row.prop === "standardHours" || row.prop === 'reportWorkHours') {
          return result + "h";
        }
        return result;
      },
      back() {
        this.$router.back()
      },
      tabClick() {
              if (this.params.radio === "InvolveWork" || this.params.radio === "SpecialInspectionRecords") {
                  setTimeout(() => {
                    if (this.$refs.sTable) {
                      this.$refs.sTable.setTableData(true);
                    }
                  }, 0)
                }
            }
    }
  };
  </script>

  <style scoped lang="scss">
  .account-detail-wrapper {
    .account-detail-header {
      width: 100%;
      padding: 10px 0;
    }
    .account-detail-body {
      padding-top: 10px;
      .card-bot {
        .info {
          padding: 20px;
          border-bottom: 1px solid #dde3eb;
        }
      }
      ::v-deep  {
        .el-tabs .el-tabs__content {
          padding: 0;
        }
     }
    }
    .order-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 60px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 100;
      background: #fff;
      border-top: 1px solid #dde3eb;
      .footer-le {
        padding-left: 30px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #393d60;
        span {
          margin-right: 45px;
          display: inline-block;
        }
      }
      .footer-ri {
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .drawing-img {
    width: 50px;
    height: 48px;
    display: block;
    cursor: pointer;
    color: #fff;
  }
  .page-container {
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .result-wrapper {
    > div {
      display: flex;
      font-size: 16px;
      padding: 10px 0;
      span {
        &:first-child {
          width: 80px;
          text-align-last: justify;
          text-align: justify;
        }
      }
    }
  }
  ::v-deep {
    .table-row-img {
      > img {
        height: 50px;
      }
    }
  }
  ::v-deep {
    .el-form-item__content {
      height: 32px;
    }
  }
  .all-select {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .checkAll {
      margin-right: 20px;
    }
    .all-li {
      width: 80px;
      height: 26px;
      background: #ffffff;
      border-radius: 16px;
      border: 1px solid #dde3eb;
      margin-right: 10px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #a4b3c6;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .active {
      color: #607fff;
      border-color: #607fff;
      background: #eff2ff;
    }
  }
  </style>
