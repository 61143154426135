var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "account-detail-wrapper" }, [
    _c("div", { staticClass: "account-detail-body" }, [
      _c(
        "div",
        {
          staticClass: "detail-card",
          staticStyle: { "margin-bottom": "20px" }
        },
        [
          _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
          _c(
            "div",
            { staticClass: "card-bot", staticStyle: { padding: "15px" } },
            [
              _c(
                "div",
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        padding: "10px 15px",
                        "flex-flow": "row wrap"
                      },
                      attrs: { type: "flex", justify: "start", gutter: 24 }
                    },
                    _vm._l(_vm.getBaseColumns, function(item) {
                      return _c(
                        "el-col",
                        {
                          key: item.prop,
                          style: { marginBottom: "20px" },
                          attrs: { span: 6 }
                        },
                        [
                          _c("span", { staticClass: "bot-tis" }, [
                            _vm._v(_vm._s(item.label) + "：")
                          ]),
                          _c("span", { staticClass: "bot-tis" }, [
                            _vm._v(_vm._s(_vm.getValue(item)))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ]
          )
        ]
      ),
      _c(
        "div",
        { staticStyle: { background: "#FFFFFF" } },
        [
          _c(
            "el-tabs",
            {
              staticClass: "detail-tabs",
              attrs: { type: "border-card" },
              on: { "tab-click": _vm.tabClick },
              model: {
                value: _vm.params.radio,
                callback: function($$v) {
                  _vm.$set(_vm.params, "radio", $$v)
                },
                expression: "params.radio"
              }
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "报工记录", name: "MaterialsChange" }
              }),
              _c("el-tab-pane", {
                attrs: { label: "计划用料", name: "MaterialPlan" }
              }),
              _c("el-tab-pane", {
                attrs: { label: "投料记录", name: "InputMaterialsRecords" }
              }),
              _c("el-tab-pane", {
                attrs: { label: "自检记录", name: "InvolveWork" }
              }),
              _c("el-tab-pane", {
                attrs: { label: "专检记录", name: "SpecialInspectionRecords" }
              }),
              _c("el-tab-pane", {
                attrs: { label: "图纸附件", name: "PlanNode" }
              }),
              _c("el-tab-pane", {
                attrs: { label: "延期记录", name: "DelayRecords" }
              })
            ],
            1
          ),
          _vm.params.radio === "MaterialsChange"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "bottom",
                    staticStyle: { padding: "15px", "margin-bottom": "38px" }
                  },
                  [
                    _c("MTable", {
                      ref: "mTable",
                      attrs: {
                        columns: _vm.tableColumns[_vm.params.radio],
                        data: _vm.reportWorkRecordList,
                        "show-page": false,
                        height: 400
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "index",
                            fn: function(ref) {
                              var $index = ref.$index
                              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                            }
                          },
                          {
                            key: "workHoursType",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  _vm._s(_vm.workHoursType[row.type]) + " "
                                )
                              ])
                            }
                          },
                          {
                            key: "planTime",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  _vm._s(row.planStartTime) +
                                    " 至 " +
                                    _vm._s(row.planEndTime) +
                                    " "
                                )
                              ])
                            }
                          }
                        ],
                        null,
                        false,
                        3921118771
                      )
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.params.radio === "InputMaterialsRecords"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "bottom",
                    staticStyle: { padding: "15px", "margin-bottom": "38px" }
                  },
                  [
                    _c("MTable", {
                      ref: "mTable",
                      attrs: {
                        columns: _vm.tableColumns[_vm.params.radio],
                        data: _vm.equipmentDetail.inputMaterialsRecordList,
                        "show-page": false,
                        height: 400
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "index",
                            fn: function(ref) {
                              var $index = ref.$index
                              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                            }
                          },
                          {
                            key: "inputMaterialsRecordStatus",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  _vm._s(row.status === 0 ? "已完成" : "已撤销")
                                )
                              ])
                            }
                          },
                          {
                            key: "mode",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(_vm._s(row.mode === 0 ? "人工" : "倒冲"))
                              ])
                            }
                          },
                          {
                            key: "isKeyMaterials",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  _vm._s(row.isKeyMaterials === 0 ? "否" : "是")
                                )
                              ])
                            }
                          },
                          {
                            key: "settingVariable",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  _vm._s(
                                    row.settingVariable === 0 ? "否" : "是"
                                  )
                                )
                              ])
                            }
                          }
                        ],
                        null,
                        false,
                        1322149466
                      )
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.params.radio === "MaterialPlan"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "bottom",
                    staticStyle: { padding: "15px", "margin-bottom": "38px" }
                  },
                  [
                    _c("MTable", {
                      ref: "mTable",
                      attrs: {
                        columns: _vm.tableColumns[_vm.params.radio],
                        data: _vm.equipmentDetail.materialsRecordList,
                        "show-page": false,
                        height: 400
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "index",
                            fn: function(ref) {
                              var $index = ref.$index
                              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                            }
                          }
                        ],
                        null,
                        false,
                        442309118
                      )
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.params.radio === "InvolveWork" ||
          _vm.params.radio === "SpecialInspectionRecords"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "bottom",
                    staticStyle: { padding: "15px", "margin-bottom": "38px" }
                  },
                  [
                    _c("MTable", {
                      ref: "sTable",
                      attrs: {
                        columns: _vm.tableColumns[_vm.params.radio],
                        "set-data-method": _vm.getTableData,
                        "show-page": true,
                        height: 400
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "index",
                            fn: function(ref) {
                              var $index = ref.$index
                              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                            }
                          },
                          {
                            key: "code",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "div",
                                {},
                                [
                                  _c("NewPageOpen", {
                                    attrs: {
                                      path: {
                                        name: "QualityTaskDetail",
                                        query: { id: row.id, type: "view" }
                                      },
                                      text: row.code
                                    }
                                  })
                                ],
                                1
                              )
                            }
                          },
                          {
                            key: "inspectionType",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  _vm._s(_vm.inspectionTypeNames[row.type])
                                )
                              ])
                            }
                          },
                          {
                            key: "inspectType",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  _vm._s(
                                    row.inspectType === 1 ? "专检" : "自检"
                                  )
                                )
                              ])
                            }
                          },
                          {
                            key: "status",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(_vm._s(_vm.statusName[row.status]))
                              ])
                            }
                          },
                          {
                            key: "result",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _c(
                                  "span",
                                  {
                                    style: {
                                      color:
                                        row.result == 0
                                          ? "#E02020"
                                          : row.result === 1
                                          ? "#00AB29"
                                          : ""
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          row.result === 1
                                            ? "合格"
                                            : row.result === 0
                                            ? "不合格"
                                            : "-"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            }
                          }
                        ],
                        null,
                        false,
                        524356292
                      )
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.params.radio === "PlanNode"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "bottom",
                    staticStyle: { padding: "15px", "margin-bottom": "38px" }
                  },
                  [
                    _c("div", { staticClass: "detail-card" }, [
                      _c(
                        "div",
                        { staticClass: "card-bot" },
                        [
                          _vm._m(0),
                          _c("br"),
                          _c("MTable", {
                            ref: "mTable",
                            attrs: {
                              "show-page": false,
                              height: 300,
                              columns: _vm.tableColumns[_vm.params.radio],
                              data: _vm.drawingsList
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "index",
                                  fn: function(ref) {
                                    var $index = ref.$index
                                    return _c("div", {}, [
                                      _vm._v(_vm._s($index + 1))
                                    ])
                                  }
                                },
                                {
                                  key: "fileName",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return _c("div", {}, [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { color: "#607FFF" },
                                          attrs: {
                                            href: row.url,
                                            download: row.name,
                                            target: "_blank"
                                          }
                                        },
                                        [_vm._v(_vm._s(row.name))]
                                      ),
                                      _c("img", {
                                        staticStyle: {
                                          width: "11px",
                                          "margin-left": "5px"
                                        },
                                        attrs: {
                                          src: require("@/assets/information/procedure/附件@2x.png")
                                        }
                                      })
                                    ])
                                  }
                                }
                              ],
                              null,
                              false,
                              4027593251
                            )
                          }),
                          _vm._m(1),
                          _c("br"),
                          _c("MTable", {
                            ref: "mTable",
                            attrs: {
                              "show-page": false,
                              height: 300,
                              columns: _vm.tableColumns[_vm.params.radio],
                              data: _vm.filesList
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "index",
                                  fn: function(ref) {
                                    var $index = ref.$index
                                    return _c("div", {}, [
                                      _vm._v(_vm._s($index + 1))
                                    ])
                                  }
                                },
                                {
                                  key: "fileName",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return _c("div", {}, [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { color: "#607FFF" },
                                          attrs: {
                                            href: row.url,
                                            download: row.name,
                                            target: "_blank"
                                          }
                                        },
                                        [_vm._v(_vm._s(row.name))]
                                      ),
                                      _c("img", {
                                        staticStyle: {
                                          width: "11px",
                                          "margin-left": "5px"
                                        },
                                        attrs: {
                                          src: require("@/assets/information/procedure/附件@2x.png")
                                        }
                                      })
                                    ])
                                  }
                                }
                              ],
                              null,
                              false,
                              4027593251
                            )
                          })
                        ],
                        1
                      )
                    ])
                  ]
                )
              ]
            : _vm._e(),
          _vm.params.radio === "DelayRecords"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "bottom",
                    staticStyle: { padding: "15px", "margin-bottom": "38px" }
                  },
                  [
                    _c("MTable", {
                      ref: "mTable",
                      attrs: {
                        columns: _vm.tableColumns[_vm.params.radio],
                        data: _vm.delayRecordsList,
                        "show-page": false,
                        height: 400
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "index",
                            fn: function(ref) {
                              var $index = ref.$index
                              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                            }
                          },
                          {
                            key: "attachments",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                row.attachments
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#6B8AFF",
                                              cursor: "pointer"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.viewPicture(row)
                                              }
                                            }
                                          },
                                          [_vm._v("查看")]
                                        ),
                                        _vm._l(
                                          _vm.formatArr(_vm.imgList),
                                          function(item) {
                                            return _c("el-image", {
                                              key: item,
                                              ref: "imgViewRef",
                                              refInFor: true,
                                              staticStyle: {
                                                width: "0px",
                                                height: "0px"
                                              },
                                              attrs: {
                                                src: item,
                                                "preview-src-list": _vm.formatArr(
                                                  _vm.imgList
                                                ),
                                                fit: "fill"
                                              }
                                            })
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  : _c("div", [_vm._v("-")])
                              ])
                            }
                          }
                        ],
                        null,
                        false,
                        261518274
                      )
                    })
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    ]),
    _c("div", { staticClass: "order-footer" }, [
      _c("div"),
      _c(
        "div",
        { staticClass: "footer-ri" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.back()
                }
              }
            },
            [_vm._v("返 回")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "b20 flex-sbc" }, [
      _c("img", {
        staticStyle: { width: "18px" },
        attrs: { src: require("@/assets/information/procedure/图纸@2x.png") }
      }),
      _c(
        "span",
        {
          staticClass: "l10",
          staticStyle: { position: "relative", top: "2px" }
        },
        [_vm._v("图纸")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bt20 flex-sbc" }, [
      _c("img", {
        staticStyle: { width: "18px" },
        attrs: {
          src: require("@/assets/information/procedure/其他附件@2x.png")
        }
      }),
      _c(
        "span",
        {
          staticClass: "l10",
          staticStyle: { position: "relative", top: "2px" }
        },
        [_vm._v("其他附件")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }