<template>
    <div class="scrap-wrap">
      <div class="top">
        <SearchForm
          ref="searchForm"
          :form-list-extend="searchFormList"
          :form-data.sync="searchFormData"
          :searchType='1'
          @search="searchTable" />
      </div>
      <div class="bottom">
        <FunctionHeader
          ref="functionHeader"
          v-model="functionHeader.searchVal"
          search-title="请输入订单编号"
          :tags="functionHeader.tags"
          :export-name="functionHeader.exportName"
          :export-params="functionHeader.exportParams"
          :import-name="functionHeader.importName"
          @search="$refs.mTable.setTableData(true)"
        >
        </FunctionHeader>
        <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" >
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <NewPageOpen slot="projectNumber" slot-scope="{ row }" :path="getDetailPath(row, 'view')" :text="row.projectNumber" />
          <div slot="deliveryType" slot-scope="{ row }" >
            <span>{{ projectDeliveryType[row.deliveryType].name }}</span>
          </div>
          <div slot="deliveryDate" slot-scope="{ row }" >
            <el-tooltip effect="dark" :content="row.deliveryDate"  placement="top-end">
            <span class="mr-10">
              {{ row.deliveryDate }}
           </span>
          </el-tooltip>
            <!-- <span>{{ row.deliveryStartDate }}~{{row.deliveryEndDate}}</span> -->
          </div>
           <div slot="businessUnit" slot-scope="{ row }" >
            <span>{{ row.businessUnit!=null ?divisionOptions[row.businessUnit - 1].name : '' }}</span>
          </div>
          <div slot="principalName" slot-scope="{row }">
          <el-tooltip effect="dark" :content="row.principalName"  placement="top-end">
            <span class="mr-10">
              {{ row.principalName }}
           </span>
          </el-tooltip>
        </div>
        <div slot="stationNumber" slot-scope="{row }">
          <el-tooltip effect="dark" popper-class="sequence-tooltip" :content="row.stationNumber" placement="top-start">
            <span class="mr-10">
              {{ row.stationNumber }}
           </span>
          </el-tooltip>
        </div>
          <div slot="executiveUnit" slot-scope="{ row }" >
            <span>{{row.executiveUnit!=null ?executionDepartment[row.executiveUnit].name : '' }}</span>
          </div>
          <div slot="projectApprovalNumber" slot-scope="{ row }" >
            <span>{{row.projectApprovalNumber }}</span>
          </div>
          <div slot="projectStatus" slot-scope="{ row }" >
            <span>{{ projectStatus[row.projectStatus].name }}</span>
          </div>
          <div slot="auditStatus" slot-scope="{ row }" >
            <span>{{ checkStatus[row.auditStatus].name }}</span>
          </div>
          <div slot="issuedStatus" slot-scope="{ row }" >
            <span>{{ issuedStatus[row.issuedStatus].name }}</span>
          </div>

          <div slot="action" slot-scope="{ row }">
            <el-button v-if="permission('IssueWorkOrder')" type="text" @click="issueWorkOrder(row)" :disabled="!row.stationNumber || row.issuedStatus === 2">下达工单</el-button>
            <el-divider v-if="permission('IssueWorkOrder')" direction="vertical" />
            <el-button v-if="permission('SetStation')" type="text" @click="setStation(row)">序列号与交期</el-button>
            <el-divider v-if="permission('SetStation')" direction="vertical" />
            <!-- <el-button v-if="permission('IssuePurchaseOrder')" type="text" @click="issuePurchaseOrder()">下达采购单</el-button>
            <el-divider v-if="permission('IssuePurchaseOrder')" direction="vertical" /> -->
            <el-dropdown @command="(command) => { more(row, command) }">
              <span class="el-dropdown-link">
                更多<i class="el-icon-arrow-down el-icon--right" />
              </span>
              <el-dropdown-menu slot="dropdown" class="td-dropdown-menu">
                <el-dropdown-item v-if="permission('Modify')" command="distribute">分配</el-dropdown-item>
                <el-dropdown-item v-if="permission('Over')" command="onOver">结案</el-dropdown-item>
                <el-dropdown-item v-if="permission('Restart')" command="restart">重启</el-dropdown-item>
                <el-dropdown-item v-if="permission('Copy')" command="copy">复制</el-dropdown-item>
                <!-- <el-dropdown-item v-if="permission('Modify')" command="modify">修改</el-dropdown-item> -->
                <el-dropdown-item v-if="permission('Del')" command="del">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </MTable>
      </div>
      <MDialog v-model="visible" :title="titles[currentType]" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList" >
        <div slot="deliveryDate">
                      <el-date-picker
                        v-show="!showTwoTime"
                        v-model="deliveryOneDate"
                        type="date"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        style="width: 100%"
                        placeholder="请选择交付日期"
                        :disabled="isDisabled"
                        @change="chooseStartTime"
                        clearable
                      />
                      <el-date-picker
                      v-show="showTwoTime"
                            v-model="deliveryTwoDate"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            format="yyyy-MM-dd"
                            clearable
                            style="width: 100%"
                            :disabled="isDisabled"
                          @change="chooseStartTime"
                          />
                    </div>
      </MFormBuilder>
    </MDialog>
    <MDialog v-model="stationVisible" title="设置序列号" @onOk="submitStationForm">
      <div v-for="item in stationList" :key="item.id">
        <div style="display: flex;margin-bottom: 10px;">
          <el-input
          v-model="item.stationNumber"
          :disabled="item.isDisabled"
          placeholder="序列号"
          style="width: 100%"
        />
        <el-date-picker
                        v-model="item.deliveryDate"
                        type="date"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        style="width: 100%;margin-left: 10px"
                        placeholder="请选择交期"
                        clearable
                      />
        </div>

      </div>
    </MDialog>
    </div>
  </template>

  <script>
  import SearchForm from '@/components/SearchForm/SearchForm'
  import { columns,getFunctionList } from './columns'
  import api from '@/api/production/plan-orders'
  import papi from '@/api/projectManagement/project'
  import conventionMixin from '@/mixin/conventional-page'
  import FunctionHeader from '@/components/FunctionHeader/index'
  import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
  import { projectDeliveryType,checkStatus,projectStatus,issuedStatus } from '@/config/options.config'
  import { divisionOptions, executionDepartment } from '@/views/plans/prod-plan-lists/data'
  import {formList, searchFormList } from './form-list'
  import processApi from '@/api/information/production/process'
  import sapi from '@/api/projectManagement/planScheme'
  import { setTreeData } from '@/utils'
  import uapi from '@/api/sets/user/user'

  export default {
    name: 'PlanOrdersList',
    components: { NewPageOpen, SearchForm, FunctionHeader },
    mixins: [conventionMixin],
    filters: {
      deliveryType(val) {
            if (val || val=== 0) return projectDeliveryType.find(item => item.id === val).name
        }
    },
    data() {
      return {
        api,
        projectNumber:'',
        projectDeliveryType,
        divisionOptions,
        executionDepartment,
        checkStatus,
        projectStatus,
        formList,
        issuedStatus,
        params: {
          radio: 'PlanOrders'
        },
        columns,
        searchFormData: {},
        defaultSearchFormData: {
          procedureId: ''
        },
        titles: {
        add: '新增',
        modify: '分配部门',
        copy: '复制'
      },
        searchFormatData: [],
        functionHeader: {
          searchVal: '',
          tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.add
            }
          }
          ],
          exportName: '',
          exportParams: {},
          importName: 'planOrderImport',
        },
        formData: {},
        currentType: 'add',
        visible: false,
        searchKeyword: true,
        isPageLoaded:false,
        materialParams: { status: 1, page: 1, size: 10, search: '',materialsId:'' },
        customerParams:{ page: 1, size: 10, search: '',customerId:''},
        planNodeList:[],
        principalLst:[],
        projectPlanId:'',
        showTwoTime:false,
        deliveryOneDate:'',
        deliveryTwoDate:[],
        searchFormList,
        isDisabled:false,
        stationList:[],
        stationVisible:false,
        projectId:''
      }
    },
    beforeRouteLeave(to, from, next) {
      if (to.name !== 'refresh') {
        this.$store.dispatch('tagsView/addCachedView', from)
      }
      next()
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 25 - 88
      }
    },
    watch: {
    'materialParams.search'(val) {
      this.formList[2].children[0].options.length = 0
      this.materialParams.page = 1
      this.$nextTick(() => {
        this.getFinishedMaterials(this.materialParams)
      })
    },
    'customerParams.search'(val) {
      this.searchFormList[2].children[0].options.length = 0
      this.formList[6].children[0].options.length = 0
      this.customerParams.page = 1
      this.$nextTick(() => {
        this.getCustomerList(this.customerParams)
      })
    }
    },
    created() {
    this.getFinishedMaterials(this.materialParams)
    this.getCustomerList(this.customerParams)
    this.getUserList()
    this.getBusinessUnitList()
    this.getOrganizationList()
    this.formList[6].directives[0].value = this.customerLoadMore
    this.formList[6].props.remoteMethod = (val) => { this.customerParams.search = val }
    this.formList[2].directives[0].value = this.finishedMaterialLoadMore
    this.formList[2].props.remoteMethod = (val) => { this.materialParams.search = val }
    },
    activated(){
    if(!this.isPageLoaded){
      this.$refs.mTable.setTableData()
    }
    this.isPageLoaded = false
    },
    beforeMount() {
    getFunctionList({
      searchChange: this.searchChange,
      searchMaterialChange: this.searchMaterialChange,
      searchCustomerChange: this.searchCustomerChange
    })
  },
    mounted() {
      this.isPageLoaded = true
    },
    methods: {
      // 更多操作
      more(row, command) {
      const functions = {
          onOver: () => { this.onOver(row.id) },
          restart: () => { this.restart(row.id) },
          del: () => { this.del(row.id) },
          modify: () => { this.modifyOrCopy(row,command) },
          copy: () => { this.modifyOrCopy(row,command) },
          distribute: () => { this.modifyOrCopy(row,command) }
        }
        functions[command]()
      },
      setStation(row){
        this.projectId = row.id
        this.stationList = row.stationList.map(item =>{
          return {
            ...item,
            isDisabled:item.stationNumber ? true : false
          }
        })
        this.stationVisible = true
      },
      async submitStationForm(callback) {
        let obj = {
            projectId:this.projectId,
            stationList:this.stationList
          }
          const res = await api.setStationNumber(
           {
            projectId:this.projectId,
            stationList:this.stationList.map(item=>{
              return {
                stationNumber:item.stationNumber,
                deliveryDate:item.deliveryDate
              }
            })
          }
          )
          if (res) {
            this.$message.success('操作成功')
            this.stationVisible = false
            this.$refs.mTable.setTableData()
          }
          callback(true)
    },
      async chooseStartTime(val) {
        await this.$refs.formBuild.setForm({
          deliveryDate:val
        })
        this.$refs.formBuild.formRefs().validateField('deliveryDate')
      },
      searchChange(val){
       this.showTwoTime = val ? true : false
       this.$refs.formBuild.setForm({
        deliveryDate: ''
        });
       this.deliveryOneDate = ''
       this.deliveryTwoDate = []
    },
    searchMaterialChange(val) {
      if (!val) {
        this.materialParams.search = ''
        this.materialParams.materialsId = ''
        this.formList[2].children[0].options = []
        this.getFinishedMaterials(this.materialParams)
      }
    },
    searchCustomerChange(val) {
      if (!val) {
        this.customerParams.search = ''
        this.customerParams.customerId = ''
        this.formList[6].children[0].options = []
        this.getCustomerList(this.customerParams)
      }
    },
      async getTableData(condition, callback) {
        const { page } = condition
        const data = Object.assign({}, ...this.searchFormatData)
        const { dateRange } = this.searchFormData
        const searchCondition = {
          projectNumber: this.functionHeader.searchVal,
          ...data,
          deliveryStartTime: dateRange ? dateRange[0] : null,
          deliveryEndTime: dateRange ? dateRange[1] : null,
          // deliveryEndStartTime: deliveryEndTime ? deliveryEndTime[0] : null,
          // deliveryEndEndTime: deliveryEndTime ? deliveryEndTime[1] : null
        }
        this.functionHeader.exportParams = searchCondition
        const res = await this.api[`get${this.params.radio}ListPage`]({
          page: page.currentPage,
          size: page.pageSize,
          ...searchCondition
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
        }
        callback()
      },
      async getProjectPlanDetail(val){
        if(val){
        const res = await sapi.getPlanSchemeDetail({id:val})
          if(res){
            this.planNodeList = res.nodeViewList.map(item=>{
              return {
                ...item,
                projectPlanId:val
              }
            })
          }
        }else{
          this.planNodeList = []
        }
      },
      issueWorkOrder(row) {
        const mergedObj = Object.assign({}, row, {projectId:row.id});
        this.$router.push({  name: 'prodPlanCreate', query: { type:'addPlanOrder',row: JSON.stringify(mergedObj) }})
    },
    issuePurchaseOrder(){
      this.$router.push({ name: 'reserveStockOrderList'})
    },
     // 结案
     onOver(id) {
         this.$confirm('您确定要结案吗?', '提示', {
             confirmButtonText: '确定',
             cancelButtonText: '取消',
             type: 'warning'
           }).then(async() => {
             const res = await api.closeOut(id)
             if (res) {
               this.$message.success('操作成功')
               this.$refs.mTable.setTableData()
             }
           })
      },
      restart(id) {
         this.$confirm('您确定要重启吗?', '提示', {
             confirmButtonText: '确定',
             cancelButtonText: '取消',
             type: 'warning'
           }).then(async() => {
             const res = await api.restart(id)
             if (res) {
               this.$message.success('操作成功')
               this.$refs.mTable.setTableData()
             }
           })
        },
    del(id) {
        this.$confirm('您确定要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const res = await api.delPlanOrder({ id:id })
          if (res) {
            this.$message.success('删除成功')
            this.$refs.mTable.setTableData()
          }
        })
      },
    // 用户下拉触底分页加载
    finishedMaterialLoadMore() {
        if (this.formList[2].children[0].options.length === this.materialsTotal) return
        if (this.formList[2].children[0].options.length > this.materialsTotal) return [...this.formList[2].children[0].options = new Set(this.formList[2].children[0].options)]
        this.materialParams.page++
        this.getFinishedMaterials(this.materialParams)
    },
    // 客户下拉触底分页加载
    customerLoadMore() {
      if (this.searchFormList[2].children[0].options.length === this.customerTotal) return
      if (this.searchFormList[2].children[0].options.length > this.customerTotal) return [...this.searchFormList[2].children[0].options = new Set(this.searchFormList[2].children[0].options)]
      if (this.formList[6].children[0].options.length === this.customerTotal) return
      if (this.formList[6].children[0].options.length > this.customerTotal) return [...this.formList[6].children[0].options = new Set(this.formList[6].children[0].options)]
      this.customerParams.page++
      this.getCustomerList(this.customerParams)
    },
    // 获取负责人
    async getUserList() {
      const res = await papi.getResponsible()
      if (res) {
        this.searchFormList[6].children[0].options = res
        this.formList[9].children[0].options = res
        this.principalLst = res
      }
    },
    // 获取业务部门
     async getBusinessUnitList() {
        const res = await papi.getBusinessUnitList()
        if (res) {
          this.searchFormList[3].children[0].options = res
        }
      },
          // 获取业务部门
     async getOrganizationList() {
        const res = await uapi.getOrganizationLevelList()
        if (res) {
          this.formList[10].props.options = setTreeData(res)
        }
      },
        // 获取成品物料
        async getFinishedMaterials(params) {
      const res = await processApi.getFinishedMaterials(params)
      if (res) {
        this.materialsTotal = res.total
        this.formList[2].children[0].options.push(...res.records || [])
      }
    },
    // 获取客户
    async getCustomerList(params) {
      const res = await processApi.getCustomerListPage(params)
      if (res) {
        this.customerTotal = res.total
        this.searchFormList[2].children[0].options.push(...res.records || [])
        this.formList[6].children[0].options.push(...res.records || [])
      }
    },
      getDetailPath(row, command) {
        return { name: 'planOrdersDetail', query: { id: row.id}}
      },
      async add() {
        this.formData = {}
        this.formList.forEach(item => {
            item.hidden = false
        })
        this.formList[4].hidden = true
        this.formList[8].hidden = false
        this.isDisabled = false
        this.formList.forEach(item => {
            item.props.disabled = false
        })
        this.formList[7].props.disabled = true
             this.showTwoTime = false
             this.deliveryOneDate = ''
             this.deliveryTwoDate = []
          this.searchFormList[2].children[0].options = []
          this.formList[2].children[0].options = []
          this.formList[6].children[0].options = []
          // this.materialParams.id = ''
          this.getProjectNumber()
          this.getFinishedMaterials(this.materialParams)
          this.getCustomerList(this.customerParams)
          this.currentType = 'add'
          this.visible = true
    },
    async getProjectNumber(){
      const res = await api.getProjectNumber()
      if(res){
        this.$refs.formBuild.setForm({
          projectNumber: res
        })
      }
    },
    async modifyOrCopy(row,command){
      this.formData = this._.cloneDeep(row)
      if(row.businessUnit){
            let res = await uapi.getOrganizationtParentPath({id:row.businessUnit,rootLevelFlag:true})
            if(res){
              this.formData.businessUnit = (res || "").split("^").map(item => +item)
            }
          }
      if(command === 'modify'){
        this.formList[4].hidden = false
        this.formList[4].props.disabled = true
        this.formList[8].hidden = true
        this.formList[7].props.disabled = true
        if(row.projectStatus === 4){
          this.isDisabled = true
          this.formList[0].props.disabled = true
          this.formList[1].props.disabled = true
          this.formList[2].props.disabled = true
          this.formList[3].props.disabled = true
          this.formList[5].props.disabled = true
          this.formList[6].props.disabled = true
          // this.formList[7].props.disabled = true
          // this.formList[8].props.disabled = true
          this.formList[9].props.disabled = true
          this.formList[10].props.disabled = true
          this.formList[11].props.disabled = true
        }else if(row.projectStatus === 2){
          this.isDisabled = false
          this.formList[0].props.disabled = true
          this.formList[1].props.disabled = true
          this.formList[2].props.disabled = true
          this.formList[3].props.disabled = true
          this.formList[5].props.disabled = false
          this.formList[6].props.disabled = false
          // this.formList[7].props.disabled = false
          // this.formList[8].props.disabled = false
          this.formList[9].props.disabled = false
          this.formList[10].props.disabled = false
          this.formList[11].props.disabled = false
        }else{
          this.isDisabled = false
          this.formList[0].props.disabled = true
          this.formList[1].props.disabled = false
          this.formList[2].props.disabled = false
          this.formList[3].props.disabled = false
          this.formList[5].props.disabled = false
          this.formList[6].props.disabled = false
          // this.formList[7].props.disabled = false
          // this.formList[8].props.disabled = false
          this.formList[9].props.disabled = false
          this.formList[10].props.disabled = false
          this.formList[11].props.disabled = false
        }
      }else if(command === 'distribute'){
        this.formList.forEach(item => {
            item.hidden = true
        })
        this.formList[10].hidden = false
      }else{
        this.formList.forEach(item => {
            item.hidden = false
        })
        this.formList[4].hidden = true
        this.formList[8].hidden = false
        this.isDisabled = false
        this.formList.forEach(item => {
          if (item.props) {
            item.props.disabled = false
          }
        })
        this.formList[7].props.disabled = true
      }
      this.showTwoTime = this.formData.deliveryType ? true : false
      this.formData.principalId = this.formData.principalId ? this.formData.principalId.split(',').map(item => +item) : this.formData.principalId
      this.currentType = command === 'copy' ? 'copy' : 'modify'
      // this.formData.deliveryDate = this.formData.deliveryType ? [this.formData.deliveryStartDate,this.formData.deliveryEndDate]:this.formData.deliveryStartDate
      this.deliveryOneDate = this.formData.deliveryDate
      this.deliveryTwoDate = this.formData.deliveryType ? [this.formData.deliveryStartDate,this.formData.deliveryEndDate] : []
      this.searchFormList[2].children[0].options = []
      this.formList[6].children[0].options = []
      this.formList[2].children[0].options = []
      this.materialParams = { status: 1, page: 1, size: 10, search: '',materialsId: row.materialsId}
      this.customerParams = { page: 1, size: 10, search: '',customerId: row.customerId}
      this.projectPlanId = row.projectPlanId
      await this.getProjectPlanDetail(row.projectPlanId)
      this.getFinishedMaterials(this.materialParams)
      this.getCustomerList(this.customerParams)
      this.visible  = true
    },
    createSetData(raw) {
      return {
        projectNumber:raw.projectNumber,
        projectName:raw.projectName,
        materialsId:raw.materialsId,
        quantity:raw.quantity,
        issuedQuantity:raw.issuedQuantity,
        customerOrderNumber:raw.customerOrderNumber,
        customerId:raw.customerId,
        deliveryType:raw.deliveryType,
        deliveryDate:raw.deliveryDate,
        // deliveryStartDate:raw.deliveryType ? raw.deliveryDate[0] :raw.deliveryDate,
        // deliveryEndDate:raw.deliveryType ? raw.deliveryDate[1] :raw.deliveryDate,
        principalId:raw.principalId ? raw.principalId.join(','):'',
        businessUnit: (raw.businessUnit || []).at(-1),
        projectApprovalNumber:raw.projectApprovalNumber,
        remark:raw.remark
      }
    },
    createResetData(raw) {
      const obj = {
        projectId: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    },
      searchTable(data,val) {
        if (val === 1) {
        this.searchFormData = {}
      }
        this.searchFormatData = data
        this.$refs.mTable.setTableData(true)
      }
    }
  }
  </script>

  <style scoped lang="scss">
  .function-header-wrap {
    padding-top: 0;
  }

  .scrap-wrap {
    width: 100%;
    padding-top: 10px;
    .bottom {
      padding: 10px;
    }
    .m-radio-group {
      margin-left: 10px;
    }
  }
  ::v-deep {
    .e-divider {
      margin: 0px 0 0 0;
    }
    .el-form-item__content {
      height:32px;
    }
        .form-builder-wrap .el-row {
          display: block;
        }
  }
  </style>
