var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "account-detail-wrapper" },
    [
      _c("div", { staticClass: "account-detail-header flex-sbc" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.back()
              }
            }
          },
          [
            _c("img", {
              staticStyle: { width: "8px" },
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c(
              "span",
              { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
              [_vm._v("订单详情")]
            )
          ]
        )
      ]),
      _c("div", { staticClass: "account-detail-body" }, [
        _c(
          "div",
          {
            staticClass: "detail-card",
            staticStyle: { "margin-bottom": "20px" }
          },
          [
            _c("div", { staticClass: "card-tit" }, [_vm._v("订单基本信息")]),
            _c(
              "div",
              { staticClass: "card-bot", staticStyle: { padding: "15px" } },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      {
                        staticClass: "view-list",
                        staticStyle: {
                          padding: "10px 15px",
                          "flex-flow": "row wrap"
                        },
                        attrs: { type: "flex", justify: "start", gutter: 24 }
                      },
                      _vm._l(_vm.columns, function(item, index) {
                        return _c(
                          "el-col",
                          {
                            key: item.prop,
                            style: { marginBottom: index !== 4 ? "20px" : 0 },
                            attrs: { span: 6 }
                          },
                          [
                            _c("span", { staticClass: "bot-tis" }, [
                              _vm._v(_vm._s(item.label) + "：")
                            ]),
                            _c("span", { staticClass: "bot-tis" }, [
                              _vm._v(_vm._s(_vm.getValue(item)))
                            ])
                          ]
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ]
        ),
        _c(
          "div",
          { staticStyle: { background: "#FFFFFF" } },
          [
            _c(
              "el-tabs",
              {
                staticClass: "detail-tabs",
                attrs: { type: "border-card" },
                on: { "tab-click": _vm.tabClick },
                model: {
                  value: _vm.params.radio,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "radio", $$v)
                  },
                  expression: "params.radio"
                }
              },
              [
                _c("el-tab-pane", {
                  attrs: { label: "工单", name: "ProjectWork" }
                }),
                _c("el-tab-pane", {
                  attrs: { label: "变更", name: "ProjectECN" }
                }),
                _c("el-tab-pane", {
                  attrs: { label: "异常", name: "ProjectAbnormal" }
                }),
                _c("el-tab-pane", {
                  attrs: { label: "用料记录", name: "MaterialRecords" }
                }),
                _c("el-tab-pane", {
                  attrs: { label: "齐套分析", name: "CompleteAnalysis" }
                }),
                _c("el-tab-pane", {
                  attrs: { label: "生产工时", name: "WorkHourStatistics" }
                }),
                _c("el-tab-pane", {
                  attrs: { label: "投料记录", name: "InputMaterialsRecord" }
                })
              ],
              1
            ),
            [
              _c(
                "div",
                { staticClass: "bottom", staticStyle: { padding: "15px" } },
                [
                  _c("FunctionHeader", {
                    attrs: {
                      tags: [],
                      "search-title": _vm.searchTitle[_vm.params.radio],
                      isDialog: true
                    },
                    on: {
                      search: function($event) {
                        return _vm.$refs.mTable.setTableData(true)
                      }
                    },
                    model: {
                      value: _vm.functionHeader.searchVal,
                      callback: function($$v) {
                        _vm.$set(_vm.functionHeader, "searchVal", $$v)
                      },
                      expression: "functionHeader.searchVal"
                    }
                  }),
                  _c("MTable", {
                    ref: "mTable",
                    attrs: {
                      "columns-key": _vm.columnsKey,
                      columns: _vm.tableColumns[_vm.params.radio],
                      "columns-setting": true,
                      "set-data-method": _vm.getTableData
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "index",
                        fn: function(ref) {
                          var $index = ref.$index
                          return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                        }
                      },
                      {
                        key: "faultStatus",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _c(
                              "div",
                              {
                                staticClass: "faultStatus",
                                style: {
                                  background:
                                    _vm.faultStatus[row.status - 1].background,
                                  color: _vm.faultStatus[row.status - 1].color
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.faultStatus[row.status - 1].name
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ])
                        }
                      },
                      {
                        key: "workOrderStatus",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.workStatus[row.workOrderStatus + 1]
                                ) +
                                " "
                            )
                          ])
                        }
                      },
                      {
                        key: "changeType",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(
                              " " + _vm._s(_vm.changeType[row.changeType]) + " "
                            )
                          ])
                        }
                      },
                      {
                        key: "status",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.abnormalOrderStatus[row.status]) +
                                " "
                            )
                          ])
                        }
                      },
                      {
                        key: "needOperate",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.needOperate[row.needOperate]) +
                                " "
                            )
                          ])
                        }
                      },
                      {
                        key: "majorType",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(
                              " " + _vm._s(_vm.majorType[row.majorType]) + " "
                            )
                          ])
                        }
                      },
                      {
                        key: "emergencyDegree",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.emergencyDegree[row.emergencyDegree]
                                ) +
                                " "
                            )
                          ])
                        }
                      },
                      {
                        key: "judgeResult",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.judgeResult[row.judgeResult]) +
                                " "
                            )
                          ])
                        }
                      },
                      {
                        key: "finalResult",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.finalResult[row.finalResult]) +
                                " "
                            )
                          ])
                        }
                      },
                      {
                        key: "processPercent",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            {},
                            [
                              _c("el-progress", {
                                attrs: { percentage: row.processPercent }
                              })
                            ],
                            1
                          )
                        }
                      },
                      {
                        key: "relatedFiles",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            {},
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "dark",
                                    content: row.relatedFiles,
                                    placement: "top-end"
                                  }
                                },
                                [
                                  _c("span", { staticClass: "mr-10" }, [
                                    _vm._v(_vm._s(row.relatedFiles))
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        }
                      },
                      {
                        key: "attachments",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            {},
                            _vm._l(JSON.parse(row.attachments) || [], function(
                              item
                            ) {
                              return _c(
                                "el-link",
                                {
                                  key: item.id,
                                  staticStyle: { "padding-left": "10px" },
                                  attrs: { href: item.url, target: "_blank" }
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              )
                            }),
                            1
                          )
                        }
                      },
                      {
                        key: "abnormalOrderCode",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("NewPageOpen", {
                            attrs: {
                              path: {
                                path: "/call/exception-handle/detail",
                                query: { id: row.id }
                              },
                              text: row.abnormalOrderCode
                            }
                          })
                        }
                      },
                      {
                        key: "prePlanChangeNumber",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("NewPageOpen", {
                            attrs: {
                              path: {
                                name: "ChangeManagementDetail",
                                query: { id: row.id }
                              },
                              text: row.prePlanChangeNumber
                            }
                          })
                        }
                      },
                      {
                        key: "receiptType",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(
                              _vm._s(_vm._f("receiptType")(row.receiptType))
                            )
                          ])
                        }
                      },
                      {
                        key: "directionType",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.directionType === 0
                                    ? "工单领料"
                                    : row.directionType === 1
                                    ? "工单退料"
                                    : "工单挪料"
                                ) +
                                " "
                            )
                          ])
                        }
                      },
                      {
                        key: "receiptStatus",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(
                              _vm._s(_vm._f("receiptStatus")(row.receiptStatus))
                            )
                          ])
                        }
                      },
                      {
                        key: "missMaterialsRate",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(_vm._s(row.missMaterialsRate) + "%")
                          ])
                        }
                      },
                      {
                        key: "completeRate",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(_vm._s(row.completeRate) + "%")
                          ])
                        }
                      },
                      {
                        key: "sitMissMaterialsRate",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(_vm._s(row.sitMissMaterialsRate) + "%")
                          ])
                        }
                      },
                      {
                        key: "isKeyMaterials",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(
                              _vm._s(row.isKeyMaterials === 1 ? "是" : "否")
                            )
                          ])
                        }
                      },
                      {
                        key: "type",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.workHoursTypeMaps[row.type] || "-") +
                                " "
                            )
                          ])
                        }
                      },
                      {
                        key: "sourceType",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.sourceTypeMaps[row.sourceType] || "-"
                                ) +
                                " "
                            )
                          ])
                        }
                      },
                      {
                        key: "userType",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(
                              " " +
                                _vm._s(["正式", "外包"][row.userType] || "-") +
                                " "
                            )
                          ])
                        }
                      },
                      {
                        key: "quantity",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            !row.isEdit
                              ? _c("div", [_vm._v(_vm._s(row.quantity))])
                              : _c("el-input", {
                                  model: {
                                    value: row.quantity,
                                    callback: function($$v) {
                                      _vm.$set(row, "quantity", $$v)
                                    },
                                    expression: "row.quantity"
                                  }
                                })
                          ]
                        }
                      },
                      {
                        key: "mode",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(_vm._s(row.mode === 0 ? "人工" : "倒冲"))
                          ])
                        }
                      },
                      {
                        key: "inputMaterialsRecordStatus",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(
                              _vm._s(row.status === 0 ? "已完成" : "已撤销")
                            )
                          ])
                        }
                      },
                      {
                        key: "settingVariable",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(
                              _vm._s(row.settingVariable === 0 ? "否" : "是")
                            )
                          ])
                        }
                      },
                      {
                        key: "errorCount",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _c("div", { staticStyle: { color: "#e02020" } }, [
                              _vm._v(_vm._s(_vm.getErrorCount(row)))
                            ])
                          ])
                        }
                      },
                      {
                        key: "unMaintainCount",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _c("div", { staticStyle: { color: "#e02020" } }, [
                              _vm._v(_vm._s(_vm.getUnMaintainCount(row)))
                            ])
                          ])
                        }
                      },
                      {
                        key: "principalName",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            {},
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "dark",
                                    content: row.principalName,
                                    placement: "top-end"
                                  }
                                },
                                [
                                  _c("span", { staticClass: "mr-10" }, [
                                    _vm._v(
                                      " " + _vm._s(row.principalName) + " "
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        }
                      },
                      {
                        key: "adjustRecord",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            {},
                            [
                              row.nodeAttribute === 1 || row.nodeAttribute === 3
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.viewAdjustRecord(row)
                                        }
                                      }
                                    },
                                    [_vm._v("查看 ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        }
                      },
                      {
                        key: "workAction",
                        fn: function(ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return _c(
                            "div",
                            {},
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    disabled:
                                      row.workOrderStatus != -1 &&
                                      row.workOrderStatus != 0
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.viewMaterialsDetail(
                                        row,
                                        $index
                                      )
                                    }
                                  }
                                },
                                [_vm._v("物料详情 ")]
                              )
                            ],
                            1
                          )
                        }
                      },
                      {
                        key: "workOrderNumber",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _c(
                              "a",
                              {
                                staticStyle: {
                                  color: "#607fff",
                                  "font-weight": "500"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.viewWorkOrderDetail(row)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(row.workOrderNumber))]
                            )
                          ])
                        }
                      },
                      {
                        key: "projectEcnAction",
                        fn: function(ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return _c(
                            "div",
                            {},
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.viewEcnChangeDetail(
                                        row,
                                        $index
                                      )
                                    }
                                  }
                                },
                                [_vm._v("变更详情")]
                              )
                            ],
                            1
                          )
                        }
                      },
                      {
                        key: "projectAbnormalAction",
                        fn: function(ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return _c(
                            "div",
                            {},
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.viewAbnormalDetail(row, $index)
                                    }
                                  }
                                },
                                [_vm._v("异常详情")]
                              )
                            ],
                            1
                          )
                        }
                      },
                      {
                        key: "inputMaterialsRecordAction",
                        fn: function(ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return _c(
                            "div",
                            {},
                            [
                              row.status === 0
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.cancelInputMaterialsRecord(
                                            row
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("撤销")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ]
          ],
          2
        )
      ]),
      _c("select-materials", {
        attrs: {
          visible: _vm.showMaterials,
          "select-materials": _vm.selectedMaterialList,
          single: false
        },
        on: {
          "update:visible": function($event) {
            _vm.showMaterials = $event
          },
          submitForm: _vm.submitMaterials
        }
      }),
      _c(
        "MDialog",
        {
          attrs: { title: "调整记录", "show-footer": false },
          model: {
            value: _vm.viewAdjustRecordVisible,
            callback: function($$v) {
              _vm.viewAdjustRecordVisible = $$v
            },
            expression: "viewAdjustRecordVisible"
          }
        },
        [
          _c("MTable", {
            attrs: {
              columns: _vm.adjustRecordColumns,
              height: 500,
              data: _vm.viewAdjustRecordList,
              "show-page": false,
              "columns-setting": false
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }