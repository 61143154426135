// let functionList = {}
import { transformColumnsToForm } from '@/utils'
// export const getFunctionList = val => {
//   functionList = val
// }

// 业务部门
export const divisionOptions = [
  { name: '新能源', key: 1, id: 1 },
  { name: '精密电子', key: 2, id: 2 },
  { name: '半导体', key: 3, id: 3 },
  { name: '现代显示', key: 4, id: 4 },
  { name: '展德自动化', key: 5, id: 5 }
]
// 工时归属
export const hoursVestInOptions = [
  { name: '生产运营', key: 0, id: 0 },
  { name: '新能源', key: 1, id: 1 },
  { name: '精密电子', key: 2, id: 2 },
  { name: '半导体', key: 3, id: 3 },
  { name: '现代显示', key: 4, id: 4 },
  { name: '展德自动化', key: 5, id: 5 }
]
export const workStatus = [
  { name: '未开工', key: -1 },
  { name: '进行中', key: 0 },
  { name: '已结案', key: 1 },
  { name: '装配完工', key: 2 },
  { name: '完工', key: 3 },
  { name: '入库', key: 4 },
  { name: '冻结', key: 5 }
]
export const progressStatus = [
  { name: '正常', color:'#409eff',key: 0 },
  { name: '逾期风险', color:'#F5A623', key: 1 },
  { name: '已经逾期', color:'#F20909', key: 2 }
]
// 执行部门
export const executionDepartment = [
  { name: '生产运营', key: 0 ,id: 0},
  { name: '事业部', key: 1,id: 1},
  { name: '外协-勤研', key: 2,id: 2},
  { name: '其他', key: 3,id: 3}
]
// 工单类型
export const workType = [
  { name: '生产工单', key: 0 },
  // { name: '模组工单', key: 1 },
  // { name: '改造工单', key: 2 }
]
export const divisionOptionsMaps = divisionOptions.reduce((pre, cur) => ({ ...pre, [cur.key]: cur.name }), {})
export const hoursVestInOptionsMaps = hoursVestInOptions.reduce((pre, cur) => ({ ...pre, [cur.key]: cur.name }), {})
export const workStatusMaps = workStatus.reduce((pre, cur) => ({ ...pre, [cur.key]: cur.name }), {})
export const progressStatusMaps = progressStatus.reduce((pre, cur) => ({ ...pre, [cur.key]: cur.name }), {})
export const progressStatusColorMaps = progressStatus.reduce((pre, cur) => ({ ...pre, [cur.key]: cur.color }), {})
export const executionDepartmentMaps = executionDepartment.reduce((pre, cur) => ({ ...pre, [cur.key]: cur.name }), {})
export const workTypeMaps = workType.reduce((pre, cur) => ({ ...pre, [cur.key]: cur.name }), {})
const workHoursType = [
  { name: '生产工时', id: 0 },
  // { name: '浪费工时', id: 1 },
  { name: '返工', id: 2 },
  { name: '停工', id: 11 },
  { name: '5S', id: 3 },
  { name: '会议', id: 4 },
  { name: '培训', id: 5 },
  { name: '入职', id: 6 },
  { name: '售后', id: 7 },
  { name: '改造', id: 8 },
  { name: '实验室', id: 9 },
  { name: '其他', id: 10 }
]
export const workHoursTypeMaps = workHoursType.reduce((pre, cur) => ({ ...pre, [cur.id]: cur.name }), {})

const sourceType = [
  { name: '独立报工', id: 0 },
  { name: '工作日报', id: 1 },
  { name: '批量报工', id: 2 },
  { name: '上下工报工', id: 3 },
]
export const sourceTypeMaps = sourceType.reduce((pre, cur) => ({ ...pre, [cur.id]: cur.name }), {})

export const preOrderStatusOptions = [
  { name: '待补工单', id: 0 },
  { name: '已补工单', id: 1 }
]
export const hasProcessFlowOptions = [
  { name: '有', id: 1 },
  { name: '无', id: 0 }
]
export const preOrderStatusMaps = preOrderStatusOptions.reduce((pre, cur) => ({ ...pre, [cur.id]: cur.name }), {})
export const hasProcessFlowMaps = hasProcessFlowOptions.reduce((pre, cur) => ({ ...pre, [cur.id]: cur.name }), {})

// 工单订单列表
export const columnsAll = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'quantity',
    label: '工单产量',
    width: 120,
    hiddenSearch: true,
    sortable: 'custom',
    form: { index: 8, tagName: 'el-input', required: false, props: { disabled: true }}
  },
  {
    prop: 'productionPlanNumber',
    label: '工单编号',
    hiddenSearch: true,
    sortable: 'custom',
    width: 220,
    slotName: 'productionPlanNumber',
    form: { index: 1, tagName: 'el-input', required: false, props: { disabled: true }}
  },
  {
    prop: 'workProcess',
    label: '报工进度',
    hiddenSearch: true,
    sortable: false,
    slotName: 'workProcess',
    width: 220,
    form: { index: 1, tagName: 'el-input', required: false, props: { disabled: true }}
  },
  {
    prop: 'requisitionTemp',
    label: '领料进度',
    hiddenSearch: true,
    sortable: false,
    slotName: 'requisitionTemp',
    width: 220,
    form: { index: 1, tagName: 'el-input', required: false, props: { disabled: true }}
  },
  // {
  //   prop: 'progressStatus',
  //   label: '工单进度',
  //   hiddenSearch: true,
  //   sortable: false,
  //   slotName: 'progressStatus',
  //   width: 120
  // },
  {
    prop: 'status',
    label: '工单状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'status',
    width: 120
  },
  {
    prop: 'areaName',
    label: '产线',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'stationName',
    label: '工位位置',
    hiddenSearch: true,
    sortable: false
  },
  // {
  //   prop: 'materialsDifference',
  //   label: '物料差异',
  //   hiddenSearch: true,
  //   sortable: false,
  //   width: 120
  // },
  // {
  //   prop: 'abnormalFlow',
  //   label: '异常流程',
  //   hiddenSearch: true,
  //   sortable: false,
  //   width: 120
  // },
  {
    prop: 'workType',
    label: '工单类型',
    hiddenSearch: true,
    sortable: false,
    slotName: 'workType',
    width: 120
  },
  {
    prop: 'priority',
    label: '工单优先级',
    hiddenSearch: true,
    sortable: 'custom',
    slotName: 'priority',
    width: 150,
    form: {
      index: 12,
      tagName: 'el-select',
      options: [],
      defaultValue: 1
    }
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false,
    form: { index: 4, tagName: 'el-input', required: false, props: { disabled: true }},
    slotName: 'materialsCode',
    width: 180
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    width: 180,
    form: { index: 5, tagName: 'el-input', required: false, props: { disabled: true }}
  },
  {
    prop: 'specifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false,
    form: { index: 6, tagName: 'el-input', required: false, props: { disabled: true }}
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    form: { index: 7, tagName: 'el-input', required: false, props: { disabled: true }}
  },
  {
    prop: 'processFlowName',
    label: '工艺',
    hiddenSearch: true,
    sortable: false,
    slotName: 'processFlowName',
    width: 180,
    form: {
      index: 9,
      key: 'processFlowId',
      tagName: 'div',
      customSlots: ['process']
    }
  },
  {
    prop: 'planStartTime',
    label: '计划开工时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180,
    form: {
      index: 13,
      key: 'planStartTime',
      tagName: 'el-date-picker',
      required: false,
      props: {
        align: 'center',
        type: 'datetime',
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        format: 'yyyy-MM-dd HH:mm'
      }
    }
  },
  {
    prop: 'planEndTime',
    label: '计划完工时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180,
    form: {
      index: 14,
      key: 'planEndTime',
      tagName: 'el-date-picker',
      required: false,
      props: {
        align: 'center',
        type: 'datetime',
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        format: 'yyyy-MM-dd HH:mm'
      }
    }
  },
  // {
  //   prop: 'deliveryTime',
  //   label: '发货时间',
  //   hiddenSearch: true,
  //   sortable: 'custom',
  //   width: 180
  // },
  // {
  //   prop: 'businessUnit',
  //   label: '业务部门',
  //   hiddenSearch: true,
  //   sortable: false,
  //   slotName: 'businessUnit'
  // },
  // {
  //   prop: 'executionDepartment',
  //   label: '执行部门',
  //   hiddenSearch: true,
  //   sortable: false,
  //   slotName: 'executionDepartment'
  // },
  {
    prop: 'principalName',
    label: '负责人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'businessUnitName',
    label: '业务部门',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'projectNumber',
    label: '项目编号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'projectName',
    label: '项目名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'planName',
    label: '项目生产节点',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'actualStartTime',
    label: '实际开工时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },
  {
    prop: 'actualEndTime',
    label: '实际完工时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },
  // {
  //   prop: 'planAssemblyCompletionTime',
  //   label: '计划装配完工时间',
  //   hiddenSearch: true,
  //   sortable: 'custom',
  //   width: 180
  // },
  // {
  //   prop: 'assemblyCompletionTime',
  //   label: '实际装配完工时间',
  //   hiddenSearch: true,
  //   sortable: 'custom',
  //   width: 180
  // },
  {
    prop: 'createPerson',
    label: '创建人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createAt',
    label: '创建时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },

  {
    prop: 'updatePerson',
    label: '更新人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'updateAt',
    label: '更新时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: 'custom'
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 160
  }
]
export const columnsPlan = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsPicture',
    label: '物料图片',
    hiddenSearch: true,
    sortable: false,
    slotName: 'materialsPicture'
  },
  {
    prop: 'specifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'quantity',
    label: '工单数量',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'batchNumber',
    label: '生产批次码',
    slotName: 'batchNumber',
    hiddenSearch: true,
    sortable: false,
    width: 220
  }
]
export const columnsPlanTime = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'productionPlanNumber',
    label: '生产工单号',
    hiddenSearch: true,
    sortable: 'custom',
    width: 220
  },
  {
    prop: 'planStartTime',
    label: '工单开始时间',
    hiddenSearch: true,
    sortable: false,
    width: 230,
    slotName: 'planStartTime'
  },
  {
    prop: 'planEndTime',
    label: '工单结束时间',
    hiddenSearch: true,
    sortable: false,
    width: 230,
    slotName: 'planEndTime'
  }
]
export const dialogFormList = transformColumnsToForm(columnsAll)
// 详情基本信息字段
export const detailBaseFields = [
  {
    key: 'productionPlanNumber',
    name: '生产工单编号：'
  },{
    key: 'status',
    name: '工单状态：'
  },
  // {
  //   key: 'workOrderTempo',
  //   name: '工单进度：'
  // },
  {
    key: 'type',
    name: '工单类型：'
  },
  {
    key: 'materialsName',
    name: '产出物料：'
  },
  {
    key: 'specifications',
    name: '规格：'
  },
  {
    key: 'quantity',
    name: '工单数量：'
  },
  {
    key: 'mainUnitName',
    name: '单位：'
  },
  {
    key: 'priority',
    name: '工单优先级：'
  },
  {
    key: 'processFlowName',
    name: '工艺路线：'
  },
  {
    key: 'processFlowVersion',
    name: '版本号：'
  },
  // {
  //   key: 'deliveryTime',
  //   name: '发货时间'
  // },
  {
    key: 'planStartTime',
    name: '计划开工时间：'
  },
  {
    key: 'planEndTime',
    name: '计划完工时间：'
  },
  {
    key: 'actualStartTime',
    name: '实际开工时间：'
  },
  {
    key: 'actualEndTime',
    name: '实际完工时间：'
  },
  {
    key: 'areaName',
    name: '产线：'
  },
  // {
  //   key: 'assemblyCompletionTime',
  //   name: '装配完工时间：'
  // },
  // {
  //   key: 'planAssemblyCompletionTime',
  //   name: '计划装配完工时间：'
  // },
  // {
  //   key: 'createPerson',
  //   name: '创建人：'
  // },
  // {
  //   key: 'createAt',
  //   name: '创建时间：'
  // },
  // {
  //   key: 'updatePerson',
  //   name: '更新人：'
  // },
  // {
  //   key: 'updateAt',
  //   name: '更新时间：'
  // },
  // {
  //   key: 'businessUnit',
  //   name: '业务部门：'
  // }, {
  //   key: 'executiveUnit',
  //   name: '执行部门：'
  // },
  // {
  //   key: 'upWorkOrderName',
  //   name: '上阶工单：'
  // },
  {
    key: 'principalName',
    name: '负责人：'
  },{
    key: 'businessUnitName',
    name: '业务部门：'
  },{
    key: 'sequenceNumber',
    name: '序列号：'
  },{
    key: 'projectNumber',
    name: '项目编号：'
  }, {
    key: 'projectName',
    name: '项目名称：'
  },{
    key: 'planName',
    name: '项目生产节点：'
  },{
    key: 'remark',
    name: '备注：'
  }
]
// 生产任务列表字段
export const productionTaskColumn = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'code',
    label: '生产任务号',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'productionStatus',
    label: '生产状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'productionStatus',
    width: 80
  },
  {
    prop: 'producePlanNumber',
    label: '所属生产工单',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'finishedMaterialsCode',
    label: '工单物料编号',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'finishedMaterialsName',
    label: '工单物料名称',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'sequenceNumber',
    label: '产成品序列号',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'actualStartTime',
    label: '实际开始时间',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'actualEndTime',
    label: '实际完成时间',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'procedureLocationCode',
    label: '工序定位码',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    label: '工序名称',
    sortable: false,
    prop: 'procedureName',
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'procedureCode',
    label: '工序编码',
    sortable: false,
    slotName: 'procedureCode',
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    sortable: false,
    slotName: 'materialsCode',
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'planOutputQuantity',
    slotName: 'planOutputQuantity',
    label: '计划产量',
    hiddenSearch: true,
    sortable: false,
    width: 140
  },
  {
    prop: 'planTime',
    slotName: 'planTime',
    label: '计划开工时间-完工时间',
    hiddenSearch: true,
    sortable: false,
    width: 350
  },
  {
    prop: 'estimateEndTime',
    label: '预计完成时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'delayReason',
    label: '延期原因',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'feedbackTime',
    label: '反馈日期',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'executorName',
    slotName: 'executorName',
    label: '执行人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'businessUnitName',
    label: '业务部门',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'standardHours',
    label: '标准工时',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'reportWorkHours',
    label: '已报工时',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'remark',
    slotName: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'action',
    label: '操作',
    hiddenSearch: true,
    sortable: false,
    width: 220,
    slotName: 'action',
    fixed: 'right'
  }
]

export const logItemColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'userName',
    label: '生产人员',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'userGroupName',
    label: '用户组',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'userType',
    label: '员工类型',
    slotName: 'userType',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'planTime',
    label: '生产时间',
    slotName: 'planTime',
    sortable: false,
    hiddenSearch: true,
    width: 320
  },
  {
    prop: 'workHours',
    label: '合计工时/h',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]
export const delayItemColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'estimateEndTime',
    label: '预计完成时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'delayReason',
    label: '延期原因',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'attachments',
    label: '延期图片',
    sortable: false,
    slotName: 'attachments',
    hiddenSearch: true
  },
  {
    prop: 'feedbackTime',
    label: '反馈日期',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'feedbackUserName',
    label: '反馈人',
    sortable: false,
    hiddenSearch: true
  }
]

export const logColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'productionTaskCode',
    label: '生产任务号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'procedureLocationCode',
    label: '工序定位码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'procedureCode',
    label: '工序编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'procedureName',
    label: '工序名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'userName',
    label: '生产人员',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'userGroupName',
    label: '用户组',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'userType',
    label: '员工类型',
    slotName: 'userType',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'planTime',
    label: '生产时间',
    slotName: 'planTime',
    sortable: false,
    hiddenSearch: true,
    width: 320
  },
  {
    prop: 'workHours',
    label: '合计工时/h',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createAt',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]
export const completeRateColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'specifications',
    label: '型号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true,
    width: 100
  },
  {
    prop: 'quantityNumerator',
    label: '用量分子',
    sortable: false,
    hiddenSearch: true,
    width: 100
  },
  {
    prop: 'quantityDenominator',
    label: '用量分母',
    sortable: false,
    hiddenSearch: true,
    width: 100
  },
  {
    prop: 'mustQuantity',
    label: '应发数量',
    sortable: false,
    hiddenSearch: true,
    width: 100
  },
  {
    prop: 'pickedQuantity',
    label: '已领数量',
    sortable: false,
    hiddenSearch: true,
    width: 100
  },
  {
    prop: 'noPickedQuantity',
    label: '未领数量',
    sortable: false,
    hiddenSearch: true,
    width: 100
  },
  {
    prop: 'inventoryQuantity',
    label: '可用库存',
    sortable: false,
    hiddenSearch: true,
    width: 100
  }
]

// 工时统计表单字段
export const workingHoursFormList = [
  {
    colSpan: 4,
    key: 'userIds',
    tagName: 'el-select',
    props: {
      placeholder: '人员',
      multiple: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'fullName',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 8,
    key: 'planStartTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '报工记录开始开始时间',
      endPlaceholder: '结束时间',
      align: 'center',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss',
      defaultTime: ['00:00:00', '23:59:59']
    }
  },
  {
    colSpan: 8,
    key: 'planEndTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '报工记录结束开始时间',
      endPlaceholder: '结束时间',
      align: 'center',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss',
      defaultTime: ['00:00:00', '23:59:59']
    }
  },
  {
    colSpan: 4,
    key: 'procedureIds',
    tagName: 'div',
    domProps: { className: 'custom-sel' },
    children: [
      {
        slots: ['procedureIds']
      }
    ]
  },
  {
    colSpan: 4,
    key: 'userGroupId',
    tagName: 'el-select',
    props: {
      placeholder: '用户组'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 4,
    key: 'userType',
    tagName: 'el-select',
    props: {
      placeholder: '员工类型'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: [
          { name: '正式', id: 0 },
          { name: '外包', id: 1 }
        ]
      }
    ]
  },
  {
    colSpan: 4,
    key: 'type',
    tagName: 'el-select',
    props: {
      placeholder: '工时类型'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: workHoursType
      }
    ]
  },
  {
    colSpan: 4,
    key: 'workHoursReasonId',
    tagName: 'el-select',
    props: {
      placeholder: '工时原因',
      multiple: false
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 4,
    key: 'sourceType',
    tagName: 'el-select',
    props: {
      placeholder: '来源'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: sourceType
      }
    ]
  }
]

// 工时统计列表字段
export const workingHoursColumn = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'producePlanNumber',
    label: '生产工单号',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'projectName',
    label: '项目名称',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'userName',
    label: '人员',
    slotName: 'userName',
    sortable: false,
    hiddenSearch: true,
    width: 200
  },
  {
    prop: 'planStartTime',
    label: '开始时间',
    slotName: 'planStartTime',
    sortable: false,
    hiddenSearch: true,
    width: 280
  },
  {
    prop: 'planEndTime',
    label: '结束时间',
    slotName: 'planEndTime',
    sortable: false,
    hiddenSearch: true,
    width: 280
  },
  {
    prop: 'workHours',
    label: '工时/h',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    label: '工序名称',
    sortable: false,
    prop: 'procedureName',
    hiddenSearch: true,
    width: 120
  },
  // {
  //   prop: 'businessUnit',
  //   slotName: 'businessUnit',
  //   label: '工时归属',
  //   sortable: false,
  //   hiddenSearch: true,
  //   width: 120
  // },
  {
    prop: 'type',
    slotName: 'type',
    label: '工时类型',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'workHoursReasonName',
    label: '工时原因',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'sourceType',
    slotName: 'sourceType',
    label: '来源',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'userGroupName',
    label: '用户组',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'userType',
    slotName: 'userType',
    label: '员工类型',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'createAt',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true,
    width: 280
  },
  {
    prop: 'remark',
    slotName: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'action',
    label: '操作',
    hiddenSearch: true,
    sortable: false,
    width: 150,
    slotName: 'action',
    fixed: 'right'
  }
]

export const columnsPreOrder = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'productionPlanNumber',
    label: '工单编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'status',
    slotName: 'status',
    label: '状态',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'businessUnitName',
    label: '业务部门',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'planStartTime',
    label: '计划开工时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'planEndTime',
    label: '计划完工时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'hasProcessFlow',
    slotName: 'hasProcessFlow',
    label: '是否有工艺路线',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'projectNumber',
    label: '项目编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'projectName',
    label: '项目名称',
    sortable: false,
    hiddenSearch: true
  },
  // {
  //   prop: 'hasProject',
  //   slotName: 'hasProject',
  //   label: '是否已建项目',
  //   sortable: false,
  //   hiddenSearch: true
  // },
  {
    prop: 'action',
    label: '操作',
    slotName: 'action',
    sortable: false,
    fixed: 'right',
    hiddenSearch: true
  }
]

export const searchFormListPreOrder = [
  {
    colSpan: 6,
    key: 'status',
    tagName: 'el-select',
    props: {
      placeholder: 'pre工单状态'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: preOrderStatusOptions
      }
    ]
  },
  {
    colSpan: 6,
    key: 'hasProcessFlow',
    tagName: 'el-select',
    props: {
      placeholder: '是否有工艺路线'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: hasProcessFlowOptions
      }
    ]
  },
  {
    colSpan: 6,
    key: 'materialsSearch',
    tagName: 'el-input',
    defaultValue: 1,
    attrs: {
      placeholder: '请输入物料名称/编号/规格'
    }
  },
  {
    colSpan: 6,
    key: 'projectSearch',
    tagName: 'el-input',
    defaultValue: 1,
    attrs: {
      placeholder: '请输入项目编号/名称'
    }
  },
  {
    colSpan: 6,
    key: 'businessUnit',
    tagName: 'el-select',
    props: {
      placeholder: '业务部门'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 8,
    key: 'createTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '创建时间起',
      endPlaceholder: '创建时间迄',
      align: 'center',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss',
      defaultTime: ['00:00:00', '23:59:59']
    }
  }
]
// 质检项目列表
export const insepctionProjectColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName:'index',
    minWidth: 80,
    hiddenSearch: true
  },
  {
    prop: 'code',
    label: '功能码',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'code',
    label: '工序定位码',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'code',
    label: '任务编号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'code',
    label: '工序',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'name',
    label: '检验项目',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsClassName',
    label: '检验标准',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'materialsTypeName',
    label: '检测方法',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '实际值',
    sortable: false,
    hiddenSearch: true,
    slotName:'quantity',
    minWidth: 120
  },
  {
    prop: 'upperLimit',
    label: '生产人',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'downLimit',
    label: '时间',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'upperLimit',
    label: '检验人',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'downLimit',
    label: '时间',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    slotName:'remark',
    minWidth: 120
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false
  }
]

// 标准工时列表
export const standardHoursColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName:'index',
    minWidth: 80,
    hiddenSearch: true
  },
  {
    prop: 'procedureLocationCode',
    label: '工序定位码',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'procedureCode',
    label: '工序编码',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'procedureName',
    label: '工序名称',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'standardHours',
    label: '标准工时',
    sortable: false,
    hiddenSearch: true,
    slotName: 'standardHours',
  }
]

// 投料记录
export const inputMaterialsRecordColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName:'index',
    minWidth: 80,
    hiddenSearch: true
  },
  {
    prop: 'procedureLocationCode',
    label: '工序定位码',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'procedureCode',
    label: '工序编码',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'procedureName',
    label: '工序名称',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'specifications',
    label: '物料规格',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'isKeyMaterials',
    label: '关键料',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120,
    slotName: 'isKeyMaterials'
  },
  {
    prop: 'settingVariable',
    label: '设变件',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120,
    slotName: 'settingVariable'
  },
  {
    prop: 'quantity',
    label: '数量',
    sortable: false,
    hiddenSearch: true,
    minWidth: 60
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'sourceBatchNumber',
    label: '来源批次码',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'sourceSupplierName',
    label: '来源供应商',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'sourcePurchaseOrderCode',
    label: '来源采购单号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'mode',
    label: '投料方式',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120,
    slotName: 'mode'
  },
  {
    prop: 'creator',
    label: '投料人',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'createTime',
    label: '投料时间',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'status',
    label: '状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'inputMaterialsRecordStatus',
    minWidth: 60
  },
  {
    slotName: 'inputMaterialsRecordAction',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 80
  }
]

// 工位列表
export const stationColumns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'code',
    label: '工位编码',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'name',
    label: '工位名称',
    hiddenSearch: true,
    sortable: false,
  },
  {
    prop: 'lockStatus',
    label: '占用状态',
    hiddenSearch: true,
    sortable: false,
    slotName:'lockStatus',
    width: 120
  },
  {
    prop: 'sequenceNumber',
    label: '所属产成品序列号',
    hiddenSearch: true,
    sortable: false,
    width: 120
  }
]

// 延期反馈
export const delayFeedbackColumns = [
  {
    prop: 'estimateEndTime',
    label: '预计完成时间',
    sortable: false,
    hiddenSearch: true,
    form: {
      key: 'estimateEndTime',
      tagName: 'el-date-picker',
      required: true,
      props: {
        align: 'center',
        type: 'datetime',
        valueFormat: 'yyyy-MM-dd HH:mm',
        format: 'yyyy-MM-dd HH:mm'
      }
    }
  },
  {
    prop: 'delayReason',
    label: '延期原因',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', required: true, props: { type: 'text', placeholder: '请输入延期原因' } }
  },
  {
    prop: 'delayAttachments',
    label: '延期图片',
    sortable: false,
    hiddenSearch: true,
    slotName: 'delayAttachments',
    form: {
      colSpan: 24,
      tagName: 'div',
      required: false,
      customSlots: ['drawing']
    }
  }
]
